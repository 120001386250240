<template>
  <b-modal
    visible
    static
    centered
    :title="title"
    ok-variant="success"
    ok-title="Akkoord"
    cancel-variant="light"
    cancel-title="Annuleren"
    @ok="$close(true)"
    @cancel="$close(false)"
    @close="$close(false)"
  >
    <p v-if="message !== null">{{ message }}</p>
    <b-alert :show="alert !== null" :variant="alertVariant" class="mt-3">{{ alert }}</b-alert>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    message: { type: String, default: null },
    alert: { type: String, default: null },
    alertVariant: { type: String, default: "success" }
  }
};
</script>
