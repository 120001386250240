<template>
  <div v-if="loading || (!booking && stay == null)" class="d-flex flex-column h-100">
    <div class="spinner fa-2x">
      <i class="fas fa-circle-notch fa-spin" />
    </div>
  </div>
  <div v-else>
    <div class="container-fluid">
      <div class="row" :class="{ blockactive: isEditing }">
        <div class="col-xl-9 p-0">
          <h1 class="mb-3">{{ $tc("booking.booking", 1) }} voor {{ booking.bookingAddress.name }}</h1>

          <booking-header />

          <stays class="mt-5" />
        </div>

        <div class="col-xl-3">
          <payment-status />

          <hr class="thin mt-1" />

          <bill />

          <hr class="thin mt-1" />

          <activities />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Activities from "@/components/booking-details/Activities";
import PaymentStatus from "@/components/booking-details/PaymentStatus";
import Bill from "@/components/booking-details/Bill";
import BookingHeader from "@/components/booking-details/Header";
import Stays from "@/components/booking-details/Stays";

import { store, mutations } from "@/store/observable";

export default {
  components: {
    Activities,
    Bill,
    PaymentStatus,
    BookingHeader,
    Stays
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    isEditing() {
      return store.isEditing;
    }
  },
  mounted() {
    this.loading = true;

    this.fetchBooking({ id: this.$route.params.id })
      .then(() => (this.loading = false))
      .then(() => {
        //NOTE: this (temporarily) warns if a user tries to open a booking for a camping he is not currently working in
        if (this.booking.camping.id != this.currentCampsite.id) {
          this.$notify({
            group: "app",
            type: "warn",
            title: "Let op!",
            text: `Deze boeking is gemaakt voor camping <b>${this.booking.camping.name}</b>. De actieve camping is <b>${this.currentCampsite.name}</b>.`
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: "not-found" });
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        }
      });
    mutations.unlock();
  },
  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"])
  }
};
</script>
