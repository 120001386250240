<template>
  <div>
    <div class="media mb-2">
      <div class="icon mr-3"><i :class="activity.icon" /></div>
      <div class="media-body">
        <p class="small">{{ activity.message }}</p>
        <small class="text-blue">{{ activity.timestamp }}</small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.media {
  p {
    margin-bottom: 0.25rem;
  }
}
</style>

<script>
export default {
  props: {
    activity: {
      type: Object,
      default: null
    }
  }
};
</script>
