<template>
  <booking-card title="Plaats" :edit-mode.sync="editMode" :toggle-edit-mode="toggleEditMode" :allow-editing="isEditingAllowed">
    <div v-if="!booking.pitches.length || editMode" class="row group-buttons">
      <div class="col">
        <button class="btn btn-sm btn-light mr-2 mb-3" :disabled="isEditing && !editMode" @click="addItem"><i class="fa fa-plus mr-1" /> Plaats</button>
      </div>
    </div>
    <template v-if="editMode">
      <div v-for="(pitch, index) in editedPitches" :key="index" class="row group-item mb-2">
        <div class="col-3">Plaats</div>
        <div class="col">
          <v-date-picker
            mode="range"
            is-required
            locale="nl"
            :value="{ start: pitch.startDate, end: pitch.endDate }"
            :min-date="minDate"
            :max-date="maxDate"
            @drag="draggedDateRange[index] = $event"
            @input="onBookingDateRangeUpdated(index, $event)"
          >
            <b-input-group slot-scope="{ inputValue, updateValue }" class="date-picker">
              <b-input-group-text slot="prepend">
                <i class="far fa-calendar-alt" />
              </b-input-group-text>
              <b-form-input :class="[{ 'show-day-span': true, 'is-dragged': !!draggedDateRange[index] }]" :value="inputValue" @change.native="updateValue($event.target.value)" />
              <b-input-group-text slot="append" class="date-range">
                {{ getDaysSpan(draggedDateRange[index] || { start: pitch.startDate, end: pitch.endDate }) || 0 }}
              </b-input-group-text>
            </b-input-group>
          </v-date-picker>
        </div>
        <div class="col-2">
          <button class="btn btn-light button-fixed-width" :disabled="booking.bookingStatus === config.bookingStates.canceled" @click.prevent="assignPitch(index)">
            <i class="fas fa-crosshairs mr-1" />
            <span v-if="pitch.pitchNumber">{{ pitch.pitchNumber }}</span>
            <span v-else>Toewijzen</span>
          </button>
        </div>
        <div class="col-1">
          <div class="item-actions">
            <a class="remove-item" href="#" @click.prevent="removeItem(index)">
              <i class="far fa-trash-alt text" />
            </a>
          </div>
        </div>
      </div>

      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="(pitch, index) in $_.orderBy(booking.pitches, 'startDate')" :key="index" class="row">
        <div class="col-3">Plaats</div>
        <div class="col-2">
          {{ getDaysSpan(draggedDateRange[index] || { start: pitch.startDate, end: pitch.endDate }) || 0 }}
        </div>
        <div class="col-3">{{ pitch.startDate | moment("ll") }} - {{ pitch.endDate | moment("ll") }}</div>
        <div class="col-2 text-right">{{ pitch.pitchNumber }}</div>
      </div>
    </template>
  </booking-card>
</template>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import { store, mutations } from "@/store/observable";
import { DateHelpers } from "@/helpers.js";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");

import BookingCard from "@/components/booking-details/BookingCard";

import { create } from "vue-modal-dialogs";
import NotificationDialog from "@/components/common/Dialogs/NotificationDialog";
import AssignPitchDialog from "@/components/common/Dialogs/AssignPitchDialog";
const assignPitchDialog = create(AssignPitchDialog);
const notificationDialog = create(NotificationDialog, "title", "message", "alert", "alertVariant");

export default {
  components: { BookingCard },
  data() {
    return {
      config: config,
      editedPitches: [],
      draggedDateRange: [],
      editMode: false
    };
  },
  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      getStayAccommodation: "CurrentBooking/getStayAccommodation",
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed",
      isCheckedIn: "CurrentBooking/isCheckedIn",
      isCheckedOut: "CurrentBooking/isCheckedOut"
    }),
    isEditing() {
      return store.isEditing;
    },
    minDate: function () {
      return this.booking.checkInDate ? this.booking.checkInDate : this.booking.startDate;
    },
    maxDate: function () {
      return this.booking.checkOutDate ? this.booking.checkOutDate : this.booking.endDate;
    }
  },
  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    addItem: async function () {
      if (!this.editMode) {
        await this.toggleEditMode();
      }

      var latest = this.booking.startDate;

      if (this.editedPitches && this.editedPitches.length > 0) {
        latest = new Date(
          Math.max.apply(
            null,
            this.editedPitches.map((e) => new Date(e.endDate))
          )
        );
      }

      this.editedPitches.push({
        startDate: latest,
        endDate: this.booking.endDate,
        pitchNumber: ""
      });
    },

    removeItem: function (index) {
      this.editedPitches.splice(index, 1);
    },

    save: async function () {
      if (this.editedPitches.some((ep) => !ep.pitchId)) {
        await notificationDialog("Geen plaats toegewezen", "Er is geen plaats toegewezen voor de geselecteerde periode(n)");
        return;
      }

      if (DateHelpers.getOverlapping(this.editedPitches.map((p) => ({ s: p.startDate, e: p.endDate })))) {
        await notificationDialog("Overlap in periode", this.$i18n.t("pitch.overlap_error"));
        return;
      }
      BookingRepository.assignPitches(this.currentCampsite.id, this.booking.id, this.editedPitches)
        .then(() => {
          this.fetchBooking({ id: this.booking.id });
          this.toggleEditMode();
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },
    async assignPitch(index) {
      const pitchType =
        this.booking.bookingType === config.bookingTypes.longStorage || this.booking.bookingType === config.bookingTypes.shortStorage
          ? config.pitchTypes.StoragePitch
          : config.pitchTypes.CampingPitch;
      await assignPitchDialog({
        bookingId: this.booking.id,
        startDate: this.editedPitches[index].startDate,
        endDate: this.editedPitches[index].endDate,
        pitchType: pitchType,
        bookingPitch: this.editedPitches[index]
      }).then((r) => {
        if (r.pitch) {
          this.editedPitches[index].pitchId = r.pitch.pitchId;
          this.editedPitches[index].pitchNumber = r.pitch.pitchNumber;
          this.editedPitches[index].startDate = r.pitch.startDate;
          this.editedPitches[index].endDate = r.pitch.endDate;
        }
      });
    },
    onBookingDateRangeUpdated(index, range) {
      if (range) {
        range.start.setHours(12);
        range.end.setHours(12);

        this.editedPitches[index].startDate = range.start;
        this.editedPitches[index].endDate = range.end;
      }
    },
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        this.editedPitches = this.$_.orderBy(this.$_.cloneDeep(this.booking.pitches), "startDate");
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    getDaysSpan(dates) {
      var num = DateHelpers.getDaysSpan(dates);
      return this.$i18n.tc("booking.nights", num || 0);
    }
  }
};
</script>
