<template>
  <booking-card title="Overige" :edit-mode.sync="editMode" :toggle-edit-mode="toggleEditMode" :allow-editing="canBeEdited">
    <div v-if="!stay.otherProducts.length || editMode" class="row mb-2">
      <div class="col">
        <grouped-product-buttons :products="products" :on-add="addItem" :edit-mode="editMode" :disabled="!isEditing && !editMode" />
      </div>
    </div>

    <div v-if="editMode">
      <div v-for="(product, index) in editedProducts" :key="product.id" class="row group-item">
        <div class="col-3">{{ product.name }}</div>
        <div class="col-3">
          <div class="input-group mb-2">
            <input-spinner v-model="product.quantity" integer :min="1" :max="9" />
          </div>
        </div>
        <div class="item-actions">
          <a class="remove-item" href="#" @click.prevent="removeItem(index)"><i class="far fa-trash-alt text" /></a>
        </div>
      </div>
      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </div>

    <div v-for="product in $_.orderBy(stay.otherProducts, 'id')" v-else :key="product.id" class="row">
      <div class="col-3">{{ product.name }}</div>
      <div class="col-5">{{ product.quantity }} {{ product.quantity | pluralize("stuk", "stuks") }}</div>
      <div class="col-2 text-right">{{ product.price | currency() }}</div>
      <div class="col-2 text-right">{{ product.total | currency() }}</div>
    </div>
  </booking-card>
</template>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BookingCard from "@/components/booking-details/BookingCard";
import GroupedProductButtons from "@/components/common/GroupedProductButtons";
import InputSpinner from "@/components/common/InputSpinner.vue";
const StayRepository = RepositoryFactory.get("StayRepository");

import { store, mutations } from "@/store/observable";

import { create } from "vue-modal-dialogs";
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

export default {
  components: { GroupedProductButtons, BookingCard, InputSpinner },

  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      config: config,
      editMode: false,
      editedProducts: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed"
    }),
    canBeEdited() {
      return !!(this.isEditingAllowed && this.products.length);
    },
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        if (
          this.hasPayments(this.stay.id) &&
          (await confirmationDialog(
            "Overige producten wijzigen",
            "Voor deze boeking zijn één of meerdere betalingen geregistreerd. Weet je zeker dat je de overige producten wilt wijzigen?",
            "Het aanpassen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
            "warning"
          )) == false
        ) {
          return;
        }
        this.editedProducts = this.$_.cloneDeep(this.stay.otherProducts);
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    addItem: async function (product) {
      if (!this.editMode) {
        await this.toggleEditMode();
      }
      this.editedProducts.push({
        name: product.name,
        sku: product.productSku,
        quantity: 1
      });
    },
    removeItem: function (index) {
      this.editedProducts.splice(index, 1);
    },
    save: function () {
      StayRepository.updateOtherProducts(this.currentCampsite.id, this.booking.id, this.stay.id, this.editedProducts)
        .then(() => {
          this.fetchBooking({ id: this.booking.id, stayId: this.stay.id });
          this.toggleEditMode();
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
