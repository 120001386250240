var render = function render(){var _vm=this,_c=_vm._self._c;return _c('booking-card',{attrs:{"title":"Verblijf","edit-mode":_vm.editMode,"toggle-edit-mode":_vm.toggleEditMode,"allow-editing":_vm.isEditingAllowed},on:{"update:editMode":function($event){_vm.editMode=$event},"update:edit-mode":function($event){_vm.editMode=$event}}},[(!_vm.stay.accommodations.length || _vm.editMode)?_c('div',{staticClass:"row group-buttons"},[_c('div',{staticClass:"col"},[_c('grouped-product-buttons',{directives:[{name:"show",rawName:"v-show",value:(_vm.stay.accommodations.length === 0),expression:"stay.accommodations.length === 0"}],attrs:{"products":_vm.products,"on-add":_vm.addItem,"edit-mode":_vm.editMode,"disabled":_vm.isEditing && !_vm.editMode}})],1)]):_vm._e(),(_vm.editMode)?_c('div',[_vm._l((_vm.editedAccommodations),function(accommodation,index){return _c('div',{key:accommodation.id,staticClass:"row group-item"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(accommodation.name))]),_c('div',{staticClass:"col-9"},[_c('v-date-picker',{attrs:{"mode":"range","is-required":"","locale":"nl","value":{
            start: accommodation.startDate,
            end: accommodation.endDate
          },"min-date":_vm.minDate,"max-date":_vm.maxDate,"columns":2},on:{"drag":function($event){_vm.draggedDateRange[index] = $event},"input":function($event){return _vm.onBookingDateRangeUpdated(index, $event)}},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, updateValue }){return _c('b-input-group',{staticClass:"date-picker"},[_c('b-input-group-text',{attrs:{"slot":"prepend"},slot:"prepend"},[_c('i',{staticClass:"far fa-calendar-alt"})]),_c('b-form-input',{class:[
                {
                  'show-day-span': true,
                  'is-dragged': !!_vm.draggedDateRange[index]
                }
              ],attrs:{"value":inputValue},nativeOn:{"change":function($event){return updateValue($event.target.value)}}}),(!_vm.booking.checkInDate)?_c('b-input-group-text',{attrs:{"slot":"append"},slot:"append"},[_c('i',{staticClass:"fas fa-eraser",on:{"click":function($event){$event.stopPropagation();return _vm.onBookingDateRangeUpdated(index, [])}}})]):_vm._e(),_c('b-input-group-text',{staticClass:"date-range",attrs:{"slot":"append"},slot:"append"},[_vm._v(" "+_vm._s(_vm.getDaysSpan( _vm.draggedDateRange[index] || { start: accommodation.startDate, end: accommodation.endDate } ) || null)+" ")])],1)}}],null,true)})],1)])}),_c('div',{staticClass:"item-actions d-none"},[_c('a',{staticClass:"remove-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeItem(_vm.index)}}},[_c('i',{staticClass:"far fa-trash-alt text"})])]),_c('div',{staticClass:"row group-actions"},[_c('div',{staticClass:"col text-right"},[_c('button',{staticClass:"btn btn-light mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.toggleEditMode.apply(null, arguments)}}},[_vm._v("Annuleren")]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.save}},[_vm._v("Opslaan")])])])],2):_vm._l((_vm.stay.accommodations),function(accommodation){return _c('div',{key:accommodation.id,staticClass:"row"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(accommodation.name))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(_vm.stay.nightCount)+" nachten")]),_c('div',{staticClass:"col-3"},[_vm._v(" "+_vm._s(_vm._f("moment")(accommodation.startDate,"ll"))+" - "+_vm._s(_vm._f("moment")(accommodation.endDate,"ll"))+" ")]),_c('div',{staticClass:"col-2 text-right"},[_vm._v(_vm._s(_vm._f("currency")(accommodation.price)))]),_c('div',{staticClass:"col-2 text-right"},[_vm._v(_vm._s(_vm._f("currency")(accommodation.total)))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }