<template>
  <booking-card title="Verblijf" :edit-mode.sync="editMode" :toggle-edit-mode="toggleEditMode" :allow-editing="isEditingAllowed">
    <div v-if="!stay.accommodations.length || editMode" class="row group-buttons">
      <div class="col">
        <grouped-product-buttons v-show="stay.accommodations.length === 0" :products="products" :on-add="addItem" :edit-mode="editMode" :disabled="isEditing && !editMode" />
      </div>
    </div>

    <div v-if="editMode">
      <div v-for="(accommodation, index) in editedAccommodations" :key="accommodation.id" class="row group-item">
        <div class="col-3">{{ accommodation.name }}</div>
        <div class="col-9">
          <v-date-picker
            mode="range"
            is-required
            locale="nl"
            :value="{
              start: accommodation.startDate,
              end: accommodation.endDate
            }"
            :min-date="minDate"
            :max-date="maxDate"
            :columns="2"
            @drag="draggedDateRange[index] = $event"
            @input="onBookingDateRangeUpdated(index, $event)"
          >
            <b-input-group slot-scope="{ inputValue, updateValue }" class="date-picker">
              <b-input-group-text slot="prepend"><i class="far fa-calendar-alt" /></b-input-group-text>
              <b-form-input
                :class="[
                  {
                    'show-day-span': true,
                    'is-dragged': !!draggedDateRange[index]
                  }
                ]"
                :value="inputValue"
                @change.native="updateValue($event.target.value)"
              />
              <b-input-group-text v-if="!booking.checkInDate" slot="append"><i class="fas fa-eraser" @click.stop="onBookingDateRangeUpdated(index, [])" /></b-input-group-text>
              <b-input-group-text slot="append" class="date-range">
                {{
                  getDaysSpan(
                    draggedDateRange[index] || {
                      start: accommodation.startDate,
                      end: accommodation.endDate
                    }
                  ) || null
                }}
              </b-input-group-text>
            </b-input-group>
          </v-date-picker>
        </div>
      </div>

      <div class="item-actions d-none">
        <!-- intentionally hidden -->
        <a class="remove-item" href="#" @click.prevent="removeItem(index)"><i class="far fa-trash-alt text" /></a>
      </div>
      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </div>

    <div v-for="accommodation in stay.accommodations" v-else :key="accommodation.id" class="row">
      <div class="col-3">{{ accommodation.name }}</div>
      <div class="col-2">{{ stay.nightCount }} nachten</div>
      <div class="col-3">
        {{ accommodation.startDate | moment("ll") }} -
        {{ accommodation.endDate | moment("ll") }}
      </div>
      <div class="col-2 text-right">{{ accommodation.price | currency() }}</div>
      <div class="col-2 text-right">{{ accommodation.total | currency() }}</div>
    </div>
  </booking-card>
</template>

<style lang="scss" scoped>
.booking-details .card:not(:first-child):not(:last-child) {
  border-bottom: 0;
}
</style>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import { DateHelpers } from "@/helpers.js";
import BookingCard from "@/components/booking-details/BookingCard";
import GroupedProductButtons from "@/components/common/GroupedProductButtons";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const StayRepository = RepositoryFactory.get("StayRepository");
const MetricRepository = RepositoryFactory.get("MetricRepository");

import { create } from "vue-modal-dialogs";
import NotificationDialog from "@/components/common/Dialogs/NotificationDialog";
const notificationDialog = create(NotificationDialog, "title", "message", "alert", "alertVariant");
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

import { store, mutations } from "@/store/observable";

export default {
  components: { GroupedProductButtons, BookingCard },

  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      config: config,
      editMode: false,
      editedAccommodations: [],
      draggedDateRange: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      getStayAccommodation: "CurrentBooking/getStayAccommodation",
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed",
      isCheckedIn: "CurrentBooking/isCheckedIn",
      isCheckedOut: "CurrentBooking/isCheckedOut"
    }),
    minDate: function () {
      return this.isCheckedIn ? this.booking.startDate : null;
    },
    maxDate: function () {
      return this.isCheckedOut ? this.booking.endDate : null;
    },
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),

    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        if (this.isCheckedOut && (await notificationDialog("Vertrek geregistreerd", "Voor deze boeking is een vertrek geregistreerd. De duur van het verblijf kan niet meer worden aangepast."))) {
          return;
        }

        if (
          this.hasPayments(this.stay.id) &&
          (await confirmationDialog(
            "Verblijf wijzigen",
            "Voor deze boeking zijn één of meerdere betalingen geregistreerd. Weet je zeker dat je de duur van het verblijf wilt wijzigen?",
            "Het aanpassen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
            "warning"
          )) == false
        ) {
          return;
        }

        this.editedAccommodations = this.$_.cloneDeep(this.stay.accommodations);
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },

    getDaysSpan(dates) {
      dates.end = this.$moment(dates.end).set({ hour: 12 });
      var count = DateHelpers.getDaysSpan(dates);
      return this.$i18n.tc("booking.nights", count);
    },

    onBookingDateRangeUpdated: async function (index, range) {
      let currentAvailablePitches = 0;
      let previousAvailablePitches = 0;
      let pitchType = this.bookingType == config.bookingTypes.longStorage ? config.pitchTypes.StoragePitch : config.pitchTypes.CampingPitch;

      await MetricRepository.getAvailability(this.currentCampsite.id, this.booking.startDate, this.booking.endDate, pitchType).then((response) => {
        let availability = response.data.dimensions.find((x) => x.name === "Available").value;
        previousAvailablePitches = parseInt(availability, 10);
      });

      await MetricRepository.getAvailability(
        this.currentCampsite.id,
        this.$moment(range.start).startOf("day").add({ hours: 12 }).toDate(),
        this.$moment(range.end).startOf("day").add({ hours: 12 }).toDate(),
        pitchType
      ).then((response) => {
        let availability = response.data.dimensions.find((x) => x.name === "Available").value;
        currentAvailablePitches = parseInt(availability, 10);
      });

      if (
        currentAvailablePitches > config.availabilityTreshold.warning ||
        previousAvailablePitches <= currentAvailablePitches ||
        (currentAvailablePitches <= this.config.availabilityTreshold.danger &&
          (await confirmationDialog(
            this.$i18n.t("booking.no_availability_title"),
            this.$i18n.t("booking.no_availability"),
            this.$i18n.t("booking.availability", {
              count: currentAvailablePitches,
              startDate: this.$moment(range.start).format("D MMMM"),
              endDate: this.$moment(range.end).format("D MMMM")
            }),
            "danger"
          ))) ||
        previousAvailablePitches < currentAvailablePitches ||
        (currentAvailablePitches >= this.config.availabilityTreshold.danger &&
          currentAvailablePitches <= this.config.availabilityTreshold.warning &&
          (await confirmationDialog(
            this.$i18n.t("booking.low_availability_title"),
            this.$i18n.t("booking.low_availability"),
            this.$i18n.t("booking.availability", {
              count: currentAvailablePitches,
              startDate: this.$moment(range.start).format("D MMMM"),
              endDate: this.$moment(range.end).format("D MMMM")
            }),
            "warning"
          )))
      ) {
        if (null === this.booking.checkInDate) {
          this.editedAccommodations[index].startDate = range.start;
        } else if (!this.$moment(this.booking.checkInDate).startOf("day").isSame(this.$moment(range.start).startOf("day"))) {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: this.$i18n.t("accommodation.start_date_change_after_arrival_error")
          });
        }
        this.editedAccommodations[index].endDate = range.end;
      }
    },

    addItem: async function (accommodation) {
      if (!this.editMode) {
        await this.toggleEditMode();
      }

      this.editedAccommodations.push({
        name: accommodation.name,
        sku: accommodation.productSku,
        quantity: 1,
        startDate: null,
        endDate: null
      });
    },

    removeItem: function (index) {
      this.accommodation.splice(index, 1);
    },

    save: async function () {
      if (this.editedAccommodations.some((el) => this.$moment(el.endDate).diff(this.$moment(el.startDate)) < 1)) {
        await notificationDialog("Ongeldige periode", "De duur van het verblijf dient minimaal één nacht te zijn.");
        return;
      }

      StayRepository.updateAccommodations(this.currentCampsite.id, this.booking.id, this.stay.id, this.editedAccommodations)
        .then(() => {
          this.fetchBooking({ id: this.booking.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
