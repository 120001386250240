<template>
  <div v-if="booking.bookingType == config.bookingTypes.longStay">
    <b-tabs pills card vertical end>
      <b-tab v-for="stay in stays" :key="stay.id" :active="selectedStay.id == stay.id" class="p-0" @click="onSelectStay(stay)">
        <long-stay-details-accommodation v-if="booking.id === stay.id" :id="stay.id" class="mb-5" />

        <long-stay-details-stay v-if="booking.id !== stay.id" :id="stay.id" class="mb-5" />

        <payment-details class="mb-5" />

        <template slot="title">
          {{ getStayTabTitle(stay) }}
          <a v-if="isStayRemovalAllowed(stay.id)" class="remove-stay pl-2" href="#" @click="onRemoveStay(stay)">
            <i class="far fa-trash-alt text-white" />
          </a>
        </template>
      </b-tab>
      <template v-if="isStayCreationAllowed" #tabs-end>
        <b-nav-item v-if="bookingCanBeEdited" href="#" @click="onCreateStay"> <i class="fa fa-plus" /> Verblijf </b-nav-item>
      </template>
    </b-tabs>
    <create-stay-dialog />
  </div>
  <div v-else-if="booking.bookingType == config.bookingTypes.shortStay">
    <short-stay-details class="mb-5" />

    <payment-details class="mb-5" />
  </div>
  <div v-else-if="booking.bookingType == config.bookingTypes.longStorage">
    <storage-details class="mb-5" />

    <payment-details class="mb-5" />
  </div>
  <div v-else-if="booking.bookingType == config.bookingTypes.shortStorage">
    <storage-details class="mb-5" />

    <payment-details class="mb-5" />
  </div>
</template>

<style lang="scss">
a.nav-link {
  &:not(.active) {
    .remove-stay {
      display: none;
    }
  }

  .remove-stay {
    float: right;
  }
}
</style>

<script>
import config from "@/config";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

import ShortStayDetails from "@/components/booking-details/ShortStayDetails";
import LongStayDetailsAccommodation from "@/components/booking-details/LongStayDetails_Accommodation";
import LongStayDetailsStay from "@/components/booking-details/LongStayDetails_Stay";
import StorageDetails from "@/components/booking-details/StorageDetails";
import PaymentDetails from "@/components/booking-details/Payments";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const StayRepository = RepositoryFactory.get("StayRepository");

//import { create } from "vue-modal-dialogs";
import CreateStayDialog from "@/components/common/Dialogs/CreateStayDialog";
//const createStayDialog = create(CreateStayDialog);
//import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialogBs";

import { create } from "vue-modal-dialogs";
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

export default {
  components: {
    ShortStayDetails,
    LongStayDetailsAccommodation,
    LongStayDetailsStay,
    StorageDetails,
    PaymentDetails,
    CreateStayDialog
  },

  data() {
    return {
      config: config,
      products: {}
    };
  },

  computed: {
    ...mapState("CurrentBooking", ["booking", "selectedStay"]),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      isStayRemovalAllowed: "CurrentBooking/isStayRemovalAllowed",
      stays: "CurrentBooking/getStays",
      isStayCreationAllowed: "CurrentBooking/isStayCreationAllowed",
      bookingCanBeEdited: "CurrentBooking/canBeEdited"
    })
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    ...mapMutations("CurrentBooking", ["setSelectedStay"]),

    onCreateStay: function () {
      this.$bvModal.show("create-stay");
    },

    onRemoveStay: async function (stay) {
      if (
        await confirmationDialog(
          "Verblijf verwijderen",
          `Wilt u het verblijf ${this.$moment(stay.startDate).format("D MMMM")} - ${this.$moment(stay.endDate).format("D MMMM")} verwijderen?`,
          "Het verwijderen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
          "danger"
        )
      ) {
        await this.removeStay(stay);
      }
    },

    getStayTabTitle: function (stay) {
      if (this.booking.id == stay.id) {
        return this.$t("booking.season_accommodation");
      } else {
        return `${this.$tc("booking.stay.stay", 1)} ${this.$moment(stay.startDate).format("D MMMM")} - ${this.$moment(stay.endDate).format("D MMMM")}`;
      }
    },

    onSelectStay: function (stay) {
      if (!(stay === undefined) && stay.id != this.selectedStay.id) {
        this.setSelectedStay(stay);
      }
    },

    removeStay: async function (stay) {
      StayRepository.delete(this.currentCampsite.id, this.booking.id, stay.id)
        .then(() => {
          this.fetchBooking({ id: this.booking.id });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
