<template>
  <div class="booking-header">
    <div class="card">
      <div class="card-header">
        <b-dropdown class="float-right my-3" right text="Acties" variant="light">
          <b-dropdown-item :disabled="!isCalculationAllowed" @click="calculateStay">Boeking herrekenen</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="sendInvoice">Afrekening verzenden</b-dropdown-item>
          <b-dropdown-item @click="downloadInvoice">Afrekening downloaden</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-if="booking.bookingStatus !== config.bookingStates.canceled" :disabled="!isCancellationAllowed" @click="cancelBooking">Boeking annuleren</b-dropdown-item>
          <b-dropdown-item v-if="isInRole('Supervisor') && booking.bookingStatus === config.bookingStates.canceled" :disabled="!isReservationAllowed" @click="reserveBooking"
            >Boeking reserveren</b-dropdown-item
          >
          <b-dropdown-item v-if="isInRole('Supervisor')" :disabled="!isDeletionAllowed" @click="deleteBooking">Boeking verwijderen</b-dropdown-item>
        </b-dropdown>

        <h2 class="my-3">
          {{ $t("booking.booking_stay_types." + booking.bookingType) }}
        </h2>
      </div>
    </div>

    <booking-card :allow-editing="false">
      <div class="row mb-3">
        <div class="col-2">
          <strong>Status</strong>
        </div>
        <div class="col">
          <booking-status :booking="booking" />
        </div>
        <div class="col-2">
          <strong>Aangekomen</strong>
        </div>
        <div class="col">
          <button v-if="booking.checkInDate" class="btn btn-sm btn-light button-fixed-width" :disabled="booking.bookingStatus === config.bookingStates.canceled" @click.prevent="checkIn">
            <i class="fas fa-sign-in-alt mr-1" />
            {{ booking.checkInDate | moment("ll") }}
          </button>
          <button v-else class="btn btn-sm btn-light button-fixed-width" :disabled="booking.bookingStatus === config.bookingStates.canceled" @click.prevent="checkIn">
            <i class="fas fa-sign-in-alt mr-1" /> Registreer
          </button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2">
          <strong>Boeking</strong>
        </div>
        <div class="col">{{ booking.bookingNumber }}</div>
        <div class="col-2">
          <strong>Vertrokken</strong>
        </div>
        <div class="col">
          <button v-if="booking.checkOutDate" class="btn btn-sm btn-light button-fixed-width" :disabled="booking.bookingStatus === config.bookingStates.canceled" @click.prevent="checkOut">
            <i class="fas fa-sign-out-alt mr-1" />
            {{ booking.checkOutDate | moment("ll") }}
          </button>
          <button v-else class="btn btn-sm btn-light button-fixed-width" :disabled="booking.bookingStatus === config.bookingStates.canceled" @click.prevent="checkOut">
            <i class="fas fa-sign-out-alt mr-1" /> Registreer
          </button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2">
          <strong>Periode</strong>
        </div>
        <div class="col">
          {{ booking.startDate | moment("ll") }} -
          {{ booking.endDate | moment("ll") }}
        </div>
        <div class="col-2"><strong>Kenteken</strong></div>
        <div class="col">
          <b-badge v-if="booking.registrationPlate" variant="info">{{ booking.registrationPlate }}</b-badge>
          <span v-else>Niet bekend</span>
        </div>
      </div>
    </booking-card>

    <booking-card :allow-editing="false">
      <div class="row mb-3">
        <div class="col-2">
          <strong>Naam</strong>
        </div>
        <div class="col">
          {{ booking.bookingAddress.name }}
          <b-badge v-b-tooltip.hover variant="info" title="Lidnummer" class="ml-2">{{ booking.memberNumber }}</b-badge>
        </div>
        <div class="col-2">
          <strong>E-mail adres</strong>
        </div>
        <div class="col">{{ booking.bookingAddress.emailAddress }}</div>
      </div>
      <div class="row mb-3">
        <div class="col-2">
          <strong>Adres</strong>
        </div>
        <div class="col">
          {{ booking.bookingAddress.address1 }}
          <br />
          {{ booking.bookingAddress.postcode }}
          {{ booking.bookingAddress.city }}
        </div>
        <div class="col-2">
          <strong>Telefoon</strong>
        </div>
        <div class="col">{{ booking.bookingAddress.phone1 }}<br />{{ booking.bookingAddress.phone2 }}</div>
      </div>
    </booking-card>
  </div>
</template>

<style lang="scss" scoped>
.button-fixed-width {
  width: 130px;
  text-align: left;
  //position: relative;
}

.booking-header {
  .card-header {
    border-top: none;
    border-bottom: none;
  }
  .card:first-child {
    border-top-style: solid;
    border-bottom-style: dashed;
  }
  .card:not(:first-child):not(:last-child) {
    border-top-width: 0;
    border-bottom-style: dashed;
  }
  .card:last-child {
    border-top-width: 0;
    border-bottom-style: solid;
  }
}
</style>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import FileSaver from "file-saver";
import ContentDisposition from "content-disposition";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");
const StayRepository = RepositoryFactory.get("StayRepository");

import BookingCard from "@/components/booking-details/BookingCard";
import BookingStatus from "@/components/common/Status/BookingStatus";

import { create } from "vue-modal-dialogs";

import CheckInDialog from "@/components/common/Dialogs/CheckInDialog";
const checkInDialog = create(CheckInDialog);
import CheckOutDialog from "@/components/common/Dialogs/CheckOutDialog";
const checkOutDialog = create(CheckOutDialog);
import AssignPitchDialog from "@/components/common/Dialogs/AssignPitchDialog";
const assignPitchDialog = create(AssignPitchDialog);
import CalculateStayDialog from "@/components/common/Dialogs/CalculateStayDialog";
const calculateStayDialog = create(CalculateStayDialog);
import DownloadInvoiceDialog from "@/components/common/Dialogs/DownloadInvoiceDialog";
const downloadInvoiceDialog = create(DownloadInvoiceDialog);
import SendInvoiceDialog from "@/components/common/Dialogs/SendInvoiceDialog";
const sendInvoiceDialog = create(SendInvoiceDialog);
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

export default {
  components: { BookingStatus, BookingCard },
  data() {
    return {
      config: config
    };
  },

  computed: {
    ...mapState("CurrentBooking", ["booking"]),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      isInRole: "Users/isInRole",
      numberOfGuests: "CurrentBooking/getNumberOfGuests",
      hasBillingAddress: "CurrentBooking/hasBillingAddress",
      isCancellationAllowed: "CurrentBooking/isCancellationAllowed",
      isReservationAllowed: "CurrentBooking/isReservationAllowed",
      isDeletionAllowed: "CurrentBooking/isDeletionAllowed",
      isCalculationAllowed: "CurrentBooking/isCalculationAllowed"
    })
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    checkIn: async function () {
      await checkInDialog({
        startDate: this.booking.startDate,
        endDate: this.booking.endDate,
        defaultDate: this.booking.checkInDate || new Date()
      }).then((r) => {
        if (r.result && r.date == null) {
          if (this.booking.checkInDate !== null) {
            //undo the check-in of the booking
            BookingRepository.undoCheckIn(this.currentCampsite.id, this.booking.id)
              .then(() => {
                this.fetchBooking({ id: this.booking.id });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        }
        if (r.result && r.date !== null) {
          if (this.$moment(this.booking.checkInDate).startOf("day").isSame(this.$moment(r.date).startOf("day")) == false) {
            //check the booking, if the checkInDate is different from the selectedDate
            BookingRepository.checkIn(this.currentCampsite.id, this.booking.id, this.$moment(r.date))
              .then(() => {
                this.fetchBooking({ id: this.booking.id });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        }
      });
    },
    checkOut: async function () {
      await checkOutDialog({
        startDate: this.booking.checkInDate,
        endDate: this.booking.endDate,
        defaultDate: this.booking.checkOutDate || new Date()
      }).then((r) => {
        if (r.result && r.date == null) {
          if (this.booking.checkOutDate !== null) {
            //undo the check-out of the booking
            BookingRepository.undoCheckOut(this.currentCampsite.id, this.booking.id)
              .then(() => {
                this.fetchBooking({ id: this.booking.id });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        }
        if (r.result && r.date !== null) {
          if (this.$moment(this.booking.checkOutDate).startOf("day").isSame(this.$moment(r.date).startOf("day")) == false) {
            //check the booking, if the checkOutDate is different from the selectedDate
            BookingRepository.checkOut(this.currentCampsite.id, this.booking.id, this.$moment(r.date))
              .then(() => {
                this.fetchBooking({ id: this.booking.id });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        }
      });
    },
    assignPitch: async function () {
      const pitchType = this.booking.bookingType === config.bookingTypes.longStorage ? config.pitchTypes.StoragePitch : config.pitchTypes.CampingPitch;
      await assignPitchDialog({
        startDate: this.booking.startDate,
        endDate: this.booking.endDate,
        pitchType: pitchType,
        bookingPitch: this.booking.pitch
      }).then((r) => {
        if (r.result) {
          // && r.pitch == null
          if (this.booking.pitch !== null) {
            //unassign the pitch if there was a pitch assigned
            BookingRepository.unassignPitch(this.currentCampsite.id, this.booking.id)
              .then(() => {
                this.fetchBooking({ id: this.booking.id });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        }
        if (r.result && r.pitch !== null) {
          if (this.booking.pitch == null || this.booking.pitch.id !== r.pitch.pitchId) {
            //assign the pitch if there was a pitch selected
            BookingRepository.assignPitch(this.currentCampsite.id, this.booking.id, r.pitch)
              .then(() => {
                this.fetchBooking({ id: this.booking.id });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        }
      });
    },
    calculateStay: async function () {
      await calculateStayDialog({ booking: this.booking }).then((r) => {
        if (r.result) {
          StayRepository.calculateStay(this.currentCampsite.id, this.booking.id, r.stayId)
            .then(() => {
              this.fetchBooking({ id: this.booking.id });
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    downloadInvoice: async function () {
      await downloadInvoiceDialog({ booking: this.booking }).then((r) => {
        if (r.result) {
          StayRepository.downloadInvoice(this.currentCampsite.id, this.booking.id, r.stayId)
            .then((response) => {
              var cd = ContentDisposition.parse(response.headers["content-disposition"]);
              FileSaver.saveAs(new Blob([response.data]), cd.parameters.filename);
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    sendInvoice: async function () {
      await sendInvoiceDialog({ booking: this.booking }).then((r) => {
        if (r.result) {
          StayRepository.sendInvoice(this.currentCampsite.id, this.booking.id, r.stayId, r.emailAddress)
            .then(() => {
              this.fetchBooking({ id: this.booking.id });
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    cancelBooking: async function () {
      await confirmationDialog(
        "Boeking annuleren",
        `Weet je zeker dat je boeking ${this.booking.bookingNumber} wilt annuleren?`,
        "Annulering van deze boeking kan niet ongedaan gemaakt worden.",
        "warning"
      ).then((r) => {
        if (r) {
          BookingRepository.setStatus(this.currentCampsite.id, this.booking.id, "Canceled")
            .then(() => {
              this.fetchBooking({ id: this.booking.id });
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    reserveBooking: async function () {
      await confirmationDialog(
        "Boeking reserveren",
        `Weet je zeker dat je boeking ${this.booking.bookingNumber} wilt reserveren?`,
        "Dit maakt annulering van deze boeking ongedaan en maakt van deze boeking weer een reservering.",
        "warning"
      ).then((r) => {
        if (r) {
          BookingRepository.setStatus(this.currentCampsite.id, this.booking.id, "Reservation")
            .then(() => {
              this.fetchBooking({ id: this.booking.id });
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    deleteBooking: async function () {
      await confirmationDialog(
        "Boeking verwijderen",
        `Weet je zeker dat je boeking ${this.booking.bookingNumber} wilt verwijderen?`,
        "Het verwijderen van deze boeking kan niet ongedaan gemaakt worden.",
        "warning"
      ).then((r) => {
        if (r) {
          BookingRepository.deleteBooking(this.currentCampsite.id, this.booking.id)
            .then(() => {
              this.$router.push({ name: "dashboard" });
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    }
  }
};
</script>
