<template>
  <b-modal
    visible
    static
    centered
    size="xl"
    title="Plaats toewijzen"
    :ok-disabled="!bookingPitch && !selectedPitch"
    ok-variant="success"
    ok-title="Akkoord"
    cancel-variant="light"
    cancel-title="Annuleren"
    @ok="$close({ result: true, pitch: selectedPitch })"
    @cancel="$close({ result: false })"
    @close="$close({ result: false })"
  >
    <div class="mb-4">
      <b-form-group label="Periode" label-cols="2">
        <div class="form-control-plaintext">
          {{ startDate | moment("ll") }} -
          {{ endDate | moment("ll") }}
        </div>
      </b-form-group>
      <b-form-group label="Plaats" label-cols="2">
        <b-input-group>
          <b-input-group-text slot="prepend"><i class="fas fa-crosshairs"></i></b-input-group-text>
          <b-form-input :value="selectedPitch ? selectedPitch.pitchNumber : null" @change="updateSelectedPitch($event)"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-alert :show="bookingPitch && !selectedPitch" class="mt-3" variant="warning"> Er is geen plaats geselecteerd. Weet je zeker dat je geen plaats wilt toewijzen? </b-alert>
    </div>
    <div class="pitch-map">
      <b-form-row class="pl-1">
        <div v-for="(pitchBookingList, pitchNumber) in pitchBookings" :key="pitchNumber" class="mr-2 mb-2">
          <b-button
            :id="'pitchButton' + pitchNumber"
            variant="light"
            :class="getBtnAvailableClass(pitchBookingList, selectedPitch ? selectedPitch.pitchNumber : null)"
            @click.prevent="updateSelectedPitch(pitchNumber)"
          >
            {{ pitchNumber }}
          </b-button>
          <b-popover v-if="hasPitchBookings(pitchBookingList)" :target="'pitchButton' + pitchNumber" :title="pitchNumber + ' - ' + $tc('booking.booking', 2)" triggers="hover" placement="top">
            <div v-for="pitchBooking in $_.sortBy(pitchBookingList, ['startDate'])" :key="pitchBooking.bookingNumber" class="mb-3">
              <span v-if="pitchBooking.blocked === true">Geblokkeerd</span>
              <span v-else>
                <strong>{{ pitchBooking.name }}</strong
                ><br />
                {{
                  $t("pitch_booking.popover_content", {
                    bookingNumber: pitchBooking.bookingNumber,
                    bookedBy: pitchBooking.name,
                    startDate: moment(pitchBooking.startDate).format("ll"),
                    endDate: moment(pitchBooking.endDate).format("ll")
                  })
                }}
              </span>
            </div>
          </b-popover>
        </div>
      </b-form-row>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "@/style/_custom-variables";
@import "~bootstrap/scss/bootstrap";
.pitch-map {
  .btn {
    width: 3.84rem;
    border-radius: 6px;
  }
}

.btn.occupied {
  @extend .btn-light;

  cursor: no-drop !important;
}

.btn.occupiedSelf {
  @extend .btn-gray;
}

.btn.selected {
  @extend .btn-info;
}
.btn.available {
  border-color: $green;
  background-color: $green;
}
.btn.availableBeforeStartDate {
  border-color: $green;
  background: linear-gradient(135deg, $green 0%, $green 50%, $light 50%, $light 100%);
}
.btn.availableAfterEndDate {
  border-color: $green;
  background: linear-gradient(135deg, $light 0%, $light 50%, $green 50%, $green 100%);
}
</style>

<script>
import { mapState } from "vuex";
import config from "@/config";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const PitchRepository = RepositoryFactory.get("PitchRepository");

export default {
  props: {
    bookingId: { type: Number, default: 0 },
    bookingPitch: { type: Object, default: null },
    pitchType: { type: String, default: config.pitchTypes.CampingPitch },
    startDate: { type: Date, default: null },
    endDate: { type: Date, default: null }
  },

  data() {
    return {
      config: config,
      pitchBookings: [],
      selectedPitch: null
    };
  },
  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },
  mounted() {
    this.selectedPitch = this.bookingPitch;

    PitchRepository.getPitchBookings(this.currentCampsite.id, this.pitchType, this.startDate, this.endDate, 0, 1000)
      .then((response) => {
        //this.pitchBookings = this.$_.groupBy(response.data, "pitchNumber");
        this.pitchBookings = response.data.reduce(function (rv, x) {
          (rv[x["pitchNumber"]] = rv[x["pitchNumber"]] || []).push(x);
          return rv;
        }, {});
      })
      .catch((error) => {
        this.$notify({
          group: "app",
          type: "error",
          title: this.$i18n.t("general_error_title"),
          text: error.response.data.message
        });
      });
  },

  methods: {
    getBtnAvailableClass: function (pitchBookings, selectedPitchNumber) {
      if (pitchBookings[0].pitchNumber === selectedPitchNumber) return "selected";
      if (pitchBookings.filter((b) => b.bookingId === this.bookingId).length > 0) return "occupiedSelf";
      if (pitchBookings.filter((b) => b.availableBeforeStartDate && b.availableAfterEndDate).length > 0) return "available";
      if (pitchBookings.filter((b) => b.availableBeforeStartDate).length > 0) return "availableBeforeStartDate";
      if (pitchBookings.filter((b) => b.availableAfterEndDate).length > 0) return "availableAfterEndDate";

      return "occupied";
    },
    hasPitchBookings: function (pitchBookings) {
      return pitchBookings.filter((b) => b.bookingId !== null).length > 0; // this.$_.filter(pitchBookings, { available: false }).length > 0;
    },
    isPitchAvailable: function (pitchBookings) {
      return pitchBookings.filter((b) => b.availableBeforeStartDate || b.availableAfterEndDate || b.bookingId === this.bookingId).length > 0;
    },
    updateSelectedPitch: function (pitchNumber) {
      //if a pitchnumber is cleared by hand, clear the pitch
      if (pitchNumber === "") return this.clearSelectedPitch();

      const pitchBookings = this.pitchBookings[pitchNumber];
      if (!pitchBookings) {
        return this.clearSelectedPitch();
      }
      if (this.isPitchAvailable(pitchBookings)) {
        this.selectedPitch = {
          pitchId: pitchBookings[0].id,
          pitchNumber: pitchBookings[0].pitchNumber,
          startDate: this.startDate,
          endDate: this.endDate
        };
      }
    },
    clearSelectedPitch: function () {
      this.selectedPitch = null;
    }
  }
};
</script>
