<template>
  <booking-card title="Elektra" :edit-mode.sync="editMode" :toggle-edit-mode="toggleEditMode" :allow-editing="canBeEdited">
    <div v-if="!stay.electricities.length || editMode" class="row group-buttons">
      <div class="col">
        <grouped-product-buttons :products="products" :on-add="addItem" :edit-mode="editMode" :disabled-products="disabledProducts" :disabled="isEditing && !editMode" />
      </div>
    </div>

    <div v-if="editMode">
      <div v-for="(product, index) in editedElectricities" :key="product.id" class="row group-item">
        <div class="col-3">{{ product.name }}</div>
        <div v-if="product.quantityType === config.quantityTypes.position" class="col-3">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="far fa-list-alt" />
              </div>
            </div>
            <input v-model.number="product.startPosition" type="number" min="0" class="form-control" placeholder="Start positie" disabled />
          </div>
        </div>
        <div v-if="product.quantityType === config.quantityTypes.position" class="col-3">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="far fa-list-alt" />
              </div>
            </div>
            <input v-model.number="product.endPosition" type="number" min="0" class="form-control" placeholder="Eind positie" />
          </div>
        </div>
        <div v-if="product.quantityType === config.quantityTypes.position" class="col-2">{{ product.endPosition - product.startPosition }} kWh</div>
        <div class="item-actions">
          <a class="remove-item" href="#" @click.prevent="removeItem(index)">
            <i class="far fa-trash-alt text" />
          </a>
        </div>
      </div>
      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </div>

    <div v-for="electricity in $_.orderBy(stay.electricities, ['startPosition', 'id'])" v-else :key="electricity.id" class="row">
      <div class="col-3">{{ electricity.name }}</div>
      <div class="col-2">
        {{ electricity.quantity }}
        {{ electricity.quantityType === config.quantityTypes.position ? "kWh" : "nachten" }}
        <b-badge v-if="electricity.meterId" v-b-tooltip.hover variant="info" title="Meternummer" class="ml-2">{{ electricity.meterId }}</b-badge>
      </div>
      <div class="col-3">
        <span v-if="electricity.quantityType === config.quantityTypes.position">{{ electricity.startPosition }} - {{ electricity.endPosition }}</span>
        <span v-else>{{ electricity.startDate | moment("ll") }} - {{ electricity.endDate | moment("ll") }}</span>
      </div>
      <div class="col-2 text-right">{{ electricity.price | currency() }}</div>
      <div class="col-2 text-right">{{ electricity.total | currency() }}</div>
    </div>
  </booking-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import BookingCard from "@/components/booking-details/BookingCard";
import GroupedProductButtons from "@/components/common/GroupedProductButtons";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const StayRepository = RepositoryFactory.get("StayRepository");
import config from "@/config";

import { create } from "vue-modal-dialogs";
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const MemberRepository = RepositoryFactory.get("MemberRepository");
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

import { store, mutations } from "@/store/observable";

export default {
  components: { GroupedProductButtons, BookingCard },

  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      config: config,
      editMode: false,
      editedElectricities: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapState("Products", ["electricities"]),
    ...mapGetters({
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed"
    }),
    disabledProducts: function () {
      return this.$_.uniq([...this.$_.map(this.stay.electricities, "sku"), ...this.$_.map(this.editedElectricities, "sku")]);
    },
    isEditing() {
      return store.isEditing;
    },
    canBeEdited() {
      return !!(this.isEditingAllowed && this.products.length);
    }
  },
  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        if (
          this.hasPayments(this.stay.id) &&
          (await confirmationDialog(
            "Elektra wijzigen",
            "Voor deze boeking zijn één of meerdere betalingen geregistreerd. Weet je zeker dat je de elektra wilt wijzigen?",
            "Het aanpassen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
            "warning"
          )) == false
        ) {
          this.editedElectricities = null;
          return;
        }

        this.editedElectricities = this.$_.cloneDeep(this.stay.electricities);
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    addItem: async function (item) {
      if (!this.editMode) {
        await this.toggleEditMode();
      }

      var product = {
        name: item.name,
        sku: item.productSku,
        startPosition: 0,
        endPosition: 0,
        meterId: "onbekend",
        startDate: this.stay.startDate,
        endDate: this.stay.endDate
      };

      if (item.quantityType === config.quantityTypes.position && this.booking.memberId > 0) {
        await MemberRepository.getById(this.booking.memberId)
          .then((result) => {
            product.startPosition = result.data.electricityPosition;
            product.endPosition = result.data.electricityPosition;
            product.meterId = result.data.electricityMeterId;
          })
          .catch((error) => {
            var errMess = error.message;
            if (error.response.status == 404) {
              errMess = this.$i18n.t("member.get_error");
            }
            this.$notify({
              group: "app",
              type: "error",
              title: this.$i18n.t("general_error_title"),
              text: errMess
            });
          });
      }
      this.editedElectricities.push(product);
    },
    removeItem: async function (index) {
      this.editedElectricities.splice(index, 1);
    },
    save: async function () {
      await StayRepository.updateElectricities(this.currentCampsite.id, this.booking.id, this.stay.id, this.editedElectricities)
        .then(() => {
          this.fetchBooking({ id: this.booking.id, stayId: this.stay.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
