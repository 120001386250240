<template>
  <div class="booking-details">
    <div class="card">
      <div class="card-header">
        <h2 class="my-3">Details van het verblijf</h2>
      </div>
    </div>

    <duration />

    <guests v-show="filterProducts('Guest').length" :products="filterProducts('Guest')" />

    <tourist-taxes v-show="filterProducts('TouristTax').length" :products="filterProducts('TouristTax')" />

    <electricities v-show="filterProducts('Electricity').length" :products="filterProducts('Electricity')" />

    <other-products v-show="filterProducts('Product').length" :products="filterProducts('Product')" />
  </div>
</template>

<style lang="scss" scoped>
// .booking-details {
//   .card-header {
//     border-top: none;
//     border-bottom: none;
//   }
//   .card:first-child {
//     border-top-style: solid;
//     border-bottom-style: dashed;
//   }
//   .card:not(:first-child):not(:last-child) {
//     border-top-width: 0;
//     border-bottom-style: dashed;
//   }
//   .card:last-child {
//     border-top-width: 0;
//     border-bottom-style: solid;
//   }
// }
//
</style>

<script>
import config from "@/config";
import { mapState, mapGetters } from "vuex";

import Duration from "./Duration";
import Guests from "./Guests";
import TouristTaxes from "./TouristTaxes";
import Electricities from "./Electricities";
import OtherProducts from "./OtherProducts";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const ProductRepository = RepositoryFactory.get("ProductRepository");

export default {
  components: {
    OtherProducts,
    Electricities,
    TouristTaxes,
    Guests,
    Duration
  },

  props: {
    id: { type: Number, default: null }
  },

  data: function () {
    return {
      config: config,
      products: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      getBookingQueryType: "CurrentBooking/getBookingQueryType"
    })
  },

  mounted() {
    ProductRepository.getProducts(this.currentCampsite.id, this.getBookingQueryType(this.id))
      .then((response) => {
        this.products = response.data;
      })
      .catch((error) => {
        this.$notify({
          group: "app",
          type: "error",
          title: this.$i18n.t("general_error_title"),
          text: error.response.data.message
        });
      });
  },

  methods: {
    filterProducts: function (d) {
      return this.products.filter((p) => p.productDefinition === d);
    }
  }
};
</script>
