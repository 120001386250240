<template>
  <booking-card title="Toeristenbelasting" :edit-mode.sync="editMode" :toggle-edit-mode="toggleEditMode" :allow-editing="canBeEdited">
    <div v-if="!stay.touristTaxes.length || editMode" class="row group-buttons">
      <div class="col">
        <grouped-product-buttons :products="products" :disabled-products="disabledProducts" :on-add="addItem" :edit-mode="editMode" :disabled="isEditing && !editMode" />
      </div>
    </div>

    <div v-if="editMode">
      <div v-for="(product, index) in editedProducts" :key="product.id" class="row group-item">
        <div class="col-3">{{ product.name }}</div>
        <div class="col-2">
          <span v-if="product.quantityType === config.quantityTypes.personPerNight"> {{ stay.nightSum }} nachten </span>
        </div>
        <div class="col-3">
          <span v-if="product.quantityType === config.quantityTypes.personPerNight"> {{ stay.guestCount }} personen </span>
        </div>
        <div class="item-actions">
          <a class="remove-item" href="#" @click.prevent="removeItem(index)"><i class="far fa-trash-alt text" /></a>
        </div>
      </div>

      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </div>

    <div v-for="touristTax in $_.orderBy(stay.touristTaxes, 'id')" v-else :key="touristTax.id" class="row">
      <div class="col-3">{{ touristTax.name }}</div>
      <div class="col-2">
        <span v-if="touristTax.quantityType === config.quantityTypes.personPerNight"> {{ touristTax.quantity }} nachten </span>
      </div>
      <div class="col-3">
        <span v-if="touristTax.quantityType === config.quantityTypes.personPerNight"> {{ stay.guestCount }} personen </span>
      </div>
      <div class="col-2 text-right">{{ touristTax.price | currency() }}</div>
      <div class="col-2 text-right">{{ touristTax.total | currency() }}</div>
    </div>
  </booking-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BookingCard from "@/components/booking-details/BookingCard";
import GroupedProductButtons from "@/components/common/GroupedProductButtons";
const StayRepository = RepositoryFactory.get("StayRepository");
import config from "@/config";

import { store, mutations } from "@/store/observable";

import { create } from "vue-modal-dialogs";
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

export default {
  components: { GroupedProductButtons, BookingCard },

  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      config: config,
      editMode: false,
      editedProducts: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed"
    }),
    canBeEdited() {
      return !!(this.isEditingAllowed && this.products.length);
    },
    disabledProducts: function () {
      if (this.stay.guests.length === 0) {
        return this.products.map((p) => p.productSku);
      }

      return this.$_.uniq([...this.$_.map(this.stay.touristTaxes, "sku"), ...this.$_.map(this.editedTouristTaxes, "sku")]);
    },
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        if (
          this.hasPayments(this.stay.id) &&
          (await confirmationDialog(
            "Toeristenbelasting wijzigen",
            "Voor deze boeking zijn één of meerdere betalingen geregistreerd. Weet je zeker dat je de toeristenbelasting wilt wijzigen?",
            "Het aanpassen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
            "warning"
          )) == false
        ) {
          return;
        }

        this.editedProducts = this.$_.cloneDeep(this.stay.touristTaxes);
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    addItem: async function (product) {
      if (!this.editMode) {
        await this.toggleEditMode();
      }

      this.editedProducts.push({
        name: product.name,
        sku: product.productSku,
        quantity: 1
      });
    },
    removeItem: function (index) {
      this.editedProducts.splice(index, 1);
    },
    save: function () {
      StayRepository.updateTouristTaxes(this.currentCampsite.id, this.booking.id, this.stay.id, this.editedProducts)
        .then(() => {
          this.fetchBooking({ id: this.booking.id, stayId: this.stay.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
