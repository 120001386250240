<template>
  <booking-card :edit-mode.sync="editMode" :title="$t('guest.guests')" :toggle-edit-mode="toggleEditMode" :allow-editing="canBeEdited">
    <div v-if="!stay.guests.length || editMode" class="row group-buttons">
      <div class="col">
        <grouped-product-buttons :products="products" :on-add="addItem" :edit-mode="editMode" :disabled="isEditing && !editMode" />
      </div>
    </div>

    <div v-if="editMode">
      <div v-for="(guest, index) in editedGuests" :key="guest.id" class="row group-item mb-2">
        <div class="col col-3">{{ guest.name }}</div>
        <div class="col col-9">
          <v-date-picker
            mode="multiple"
            is-required
            locale="nl"
            :value="guest.dates"
            :available-dates="allowedDateRange"
            :columns="spansMultipleMonths(guest.dates)"
            @input="onGuestDatesUpdated(index, $event)"
          >
            <b-input-group slot-scope="{ inputValue, updateValue }" class="date-picker">
              <b-input-group-text slot="prepend"><i class="far fa-calendar-alt" /></b-input-group-text>
              <b-form-input :value="inputValue" @change.native="updateValue($event.target.value)" />
              <b-input-group-text slot="append"><i class="fas fa-eraser" @click.prevent="onGuestDatesUpdated(index, [])" /></b-input-group-text>
              <b-input-group-text slot="append" class="date-range">{{ getDaysCount(guest.dates) }} nachten</b-input-group-text>
            </b-input-group>
          </v-date-picker>
        </div>
        <div class="item-actions">
          <a class="remove-item" href="#" @click.prevent="removeItem(index)"><i class="far fa-trash-alt text" /></a>
        </div>
      </div>
      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </div>

    <div v-for="guest in $_.orderBy(stay.guests, ['productSku', 'id'])" v-else :key="guest.id" class="row">
      <div class="col-3">{{ guest.name }}</div>
      <div class="col-2">{{ guest.quantity }} nachten</div>
      <div class="col-3">{{ stay.startDate | moment("ll") }} - {{ stay.endDate | moment("ll") }}</div>
      <div class="col-2 text-right">{{ guest.price | currency() }}</div>
      <div class="col-2 text-right">{{ guest.total | currency() }}</div>
      <div class="col-5 offset-3">
        <span v-for="(date, index) in guest.dates" :key="index" class="badge badge-info mr-1">
          {{ date | moment("D-M") }}
        </span>
      </div>
    </div>
  </booking-card>
</template>
<style lang="scss" scoped>
.input-group-text.date-range {
  width: 7rem;
}
</style>
<script>
import config from "@/config";
import { DateHelpers } from "@/helpers.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BookingCard from "@/components/booking-details/BookingCard";
import GroupedProductButtons from "@/components/common/GroupedProductButtons";
const StayRepository = RepositoryFactory.get("StayRepository");

import { store, mutations } from "@/store/observable";

import { create } from "vue-modal-dialogs";
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

export default {
  components: { GroupedProductButtons, BookingCard },

  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      config: config,
      editMode: false,
      editedGuests: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      allowedDateRange: "CurrentBooking/getAllowedStayDateRange",
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed"
    }),
    canBeEdited() {
      return !!(this.isEditingAllowed && this.products.length);
    },
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        if (
          this.hasPayments(this.stay.id) &&
          (await confirmationDialog(
            "Equipe wijzigen",
            "Voor deze boeking zijn één of meerdere betalingen geregistreerd. Weet je zeker dat je samenstelling van de equipe wilt wijzigen?",
            "Het aanpassen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
            "warning"
          )) == false
        ) {
          return;
        }

        this.editedGuests = this.$_.cloneDeep(this.stay.guests);
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    getDaysCount: function (dates) {
      return DateHelpers.getDaysCount(dates);
    },
    spansMultipleMonths: function (dates) {
      return DateHelpers.spansMultipleMonths(dates) ? 2 : 1;
    },
    addItem: async function (guest) {
      if (!this.editMode) {
        await this.toggleEditMode();
      }

      this.editedGuests.push({
        name: guest.name,
        sku: guest.productSku,
        dates: DateHelpers.getDatesFromDateRange({ start: this.stay.startDate, end: this.stay.endDate })
      });
    },
    removeItem: function (index) {
      this.editedGuests.splice(index, 1);
    },
    onGuestDatesUpdated: function (index, dates) {
      if (dates === null) dates = [];

      this.editedGuests[index].dates = dates;
    },
    save: function () {
      StayRepository.updateGuests(this.currentCampsite.id, this.booking.id, this.stay.id, this.editedGuests)
        .then(() => {
          this.fetchBooking({ id: this.booking.id, stayId: this.stay.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
