<template>
  <div>
    <payment-status :booking="booking" class="float-right mt-1" />
    <h1>
      {{ booking.remainingTotal | currency() }}
    </h1>

    <div class="py-2 my-3">{{ paymentStatusMessage }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "@/config";
import PaymentStatus from "@/components/common/Status/PaymentStatus";

export default {
  components: { PaymentStatus },

  data() {
    return {
      config: config
    };
  },

  computed: {
    ...mapState("CurrentBooking", ["booking", "selectedStay"]),
    paymentStatusMessage: function () {
      return this.booking.remainingTotal < 0 ? "Te ontvangen" : "Te betalen";
    }
  }
};
</script>
