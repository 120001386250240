<template>
  <b-modal visible static centered :title="title" ok-only ok-variant="success" ok-title="Ok" @ok="$close(true)" @close="$close(true)">
    <p v-if="message !== null">{{ message }}</p>
    <b-alert :show="alert !== null" :variant="alertVariant" class="mt-3">{{ alert }}</b-alert>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    message: { type: String, default: null },
    alert: { type: String, default: null },
    alertVariant: { type: String, default: "success" }
  }
};
</script>
