<template>
  <div>
    <div class="d-flex flex-row">
      <div id="bill-stays" role="tablist">
        <b-card v-for="stay in stays" :key="stay.id" no-body class="mb-1 border-0">
          <b-card-header header-tag="header" class="mb-4 pb-1" role="tab">
            <b-btn v-b-toggle="'bill-accordion-' + stay.id" block href="#" class="border-0 text-left">
              {{ getStayTitle(stay) }}
            </b-btn>
          </b-card-header>
          <b-collapse :id="'bill-accordion-' + stay.id" :visible="stay.id === selectedStay.id" accordion="bill-accordion" role="tabpanel">
            <b-card-body class="p-0">
              <div v-if="stay.accommodations.length > 0" class="row">
                <div class="col-8">
                  {{ getStayAccommodation(stay.id).name }}
                </div>
                <div class="col-4 text-right">
                  {{ stay.accommodationTotal | currency() }}
                </div>
              </div>
              <div v-if="stay.guests.length > 0" class="row">
                <div class="col-8">Overnachtingen</div>
                <div class="col-4 text-right">
                  {{ stay.guestsTotal | currency() }}
                </div>
              </div>
              <div v-if="stay.touristTaxes.length > 0" class="row">
                <div class="col-8">Toeristenbelasting</div>
                <div class="col-4 text-right">
                  {{ stay.touristTaxesTotal | currency() }}
                </div>
              </div>
              <div v-if="stay.electricities.length > 0" class="row">
                <div class="col-8">Elektriciteit</div>
                <div class="col-4 text-right">
                  {{ stay.electricityTotal | currency() }}
                </div>
              </div>
              <div v-if="stay.otherProducts.length > 0" class="row">
                <div class="col-8">Overige producten</div>
                <div class="col-4 text-right">
                  {{ stay.otherProductsTotal | currency() }}
                </div>
              </div>
              <div v-if="stay.discounts.length">
                <div v-for="discount in stay.discounts" :key="discount.id" class="row my-2">
                  <div class="col-8">{{ discount.campaignItemName }}</div>
                  <div class="col-4 text-right">{{ (discount.amountOff * -1) | currency() }}</div>
                </div>
              </div>
              <div v-if="stay.paymentFeeTotal > 0" class="row">
                <div class="col-8">Transactiekosten</div>
                <div class="col-4 text-right">{{ stay.paymentFeeTotal | currency() }}</div>
              </div>
              <div class="row my-2">
                <div class="col-8 font-weight-bold">Totaal</div>
                <div class="col-4 font-weight-bold text-right">
                  {{ stay.bookingTotal | currency() }}
                </div>
              </div>
              <div class="row my-2">
                <div class="col-8">Voldaan</div>
                <div class="col-4 text-right">
                  {{ stay.paymentTotal | currency() }}
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../style/_custom-variables";

#bill-stays {
  width: 100%;

  .card {
    &.editing {
      background: darken(white, 5);
    }

    .card-header {
      background-color: transparent;
      border-bottom: 0px;
      padding-bottom: 0;

      a {
        float: right !important;
        color: grey;
        position: absolute;
        right: 10px;
        top: 10px;
      }

      a.btn {
        background-color: white;
        color: $primary;
        font-weight: bold;
      }
    }

    .card-body {
      .row.actions {
        margin-top: 10px;
      }
    }
  }
}

#bill-total {
  width: 100%;
  font-weight: bold;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import config from "@/config";

export default {
  data() {
    return {
      config: config
    };
  },

  computed: {
    ...mapState("CurrentBooking", ["booking", "selectedStay"]),
    ...mapGetters({
      stays: "CurrentBooking/getStays",
      getStayAccommodation: "CurrentBooking/getStayAccommodation"
    })
  },

  methods: {
    getStayTitle: function (stay) {
      if (this.booking.id == stay.id && this.booking.bookingType == config.bookingTypes.longStay) {
        return this.$t("booking.booking_stay_types.LongStay");
      }
      if (this.booking.id == stay.id && this.booking.bookingType == config.bookingTypes.shortStay) {
        return this.$t("booking.booking_stay_types.ShortStay");
      } else {
        return `${this.$tc("booking.booking_stay_types.Stay")} ${this.$moment(stay.startDate).format("D MMMM")} - ${this.$moment(stay.endDate).format("D MMMM")}`;
      }
    }
  }
};
</script>
