<template>
  <div class="booking-details">
    <div class="card">
      <div class="card-header">
        <h2 class="my-3">Details van het verblijf</h2>
      </div>
    </div>

    <accommodation v-show="filterProducts('LongStay').length" :products="filterProducts('LongStay')" />

    <booking-pitches v-show="filterProducts('LongStay').length" />

    <tourist-taxes v-show="filterProducts('TouristTax').length || stay.touristTaxes?.length" :products="filterProducts('TouristTax')" />
  </div>
</template>

<script>
import config from "@/config";
import { mapState, mapGetters } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const ProductRepository = RepositoryFactory.get("ProductRepository");

import Accommodation from "@/components/booking-details/Accommodation";
import TouristTaxes from "@/components/booking-details/TouristTaxes";

import BookingPitches from "@/components/booking-details/Pitches";

export default {
  components: {
    TouristTaxes,
    Accommodation,
    BookingPitches
  },

  props: {
    id: { type: Number, default: null }
  },

  data: function () {
    return {
      config: config,
      products: []
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      getBookingQueryType: "CurrentBooking/getBookingQueryType"
    })
  },

  mounted() {
    ProductRepository.getProducts(this.currentCampsite.id, this.getBookingQueryType(this.id))
      .then((response) => {
        this.products = response.data;
      })
      .catch((error) => {
        this.$notify({
          group: "app",
          type: "error",
          title: this.$i18n.t("general_error_title"),
          text: error.response.data.message
        });
      });
  },

  methods: {
    filterProducts: function (d) {
      return this.products.filter((p) => p.productDefinition === d);
    }
  }
};
</script>
