<template>
  <div class="card" :class="{ editing: editMode }" @click="toggleBlockEdit">
    <div v-if="title" class="card-header">
      <h3>{{ title }}</h3>
      <!-- 
      <a v-if="!editMode && allowEditing" href="#" role="actions" @click.prevent="toggleEditMode"><i class="fas fa-pen edit"></i></a>
      <a v-else-if="editMode" href="#" role="actions" @click.prevent.stop="toggleEditMode"><i class="fa fa-times" /></a> -->

      <a v-if="allowEditing" href="#" role="actions" @click.prevent.stop="toggleEditMode">
        <i v-if="editMode" class="fa fa-times" />
        <i v-else class="fas fa-pen edit" />
      </a>

      <span v-else class="fa-stack prohibited">
        <i class="fas fa-pen fa-stack-1x"></i>
        <i class="fas fa-slash fa-stack-1x"></i>
      </span>
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../style/_custom-variables";

.card {
  &.editing {
    background: #f8f8f8;
  }

  .card-header {
    border-top: none;
    border-bottom: none;

    .prohibited,
    a {
      float: right !important;
      color: $gray-300;
      position: absolute;
      right: 10px;
      top: 10px;

      &:hover {
        color: $primary;
      }
    }
  }

  .card-body {
    .row.actions {
      margin-top: 10px;
    }
  }
}

div:not(.booking-header) > .card:hover:not(.editing) {
  background: #f8f8f8;
  cursor: pointer;
  a .edit {
    color: $primary;
  }
}

.prohibited {
  cursor: not-allowed;
}
</style>

<script>
import { store } from "@/store/observable";

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    editMode: {
      type: Boolean,
      default: false
    },
    allowEditing: {
      type: Boolean,
      default: true
    },
    toggleEditMode: {
      type: Function,
      default: async function () {}
    }
  },
  computed: {
    isEditing() {
      return store.isEditing;
    }
  },
  methods: {
    toggleBlockEdit() {
      if (this.allowEditing && !this.editMode && !this.isEditing) {
        this.toggleEditMode();
      }
    }
  }
};
</script>
