<template>
  <div class="mb-2">
    <template v-for="product in products">
      <b-dropdown
        v-if="product.variants && product.variants.length > 0"
        :key="product.productSku"
        variant="light"
        :disabled="disabled || (product.variants && product.variants.every((p) => disabledProducts.indexOf(p.productSku) !== -1))"
        class="mr-2 mb-2"
        size="sm"
      >
        <template slot="button-content"><i class="fas fa-plus mr-1" /> {{ product.name }}</template>
        <b-dropdown-item-button
          v-for="productVariant in product.variants"
          :key="productVariant.productSku"
          :disabled="disabledProducts.indexOf(productVariant.productSku) !== -1"
          @click="onAdd(productVariant)"
        >
          {{ productVariant.name.replace(product.name + " - ", "") }}</b-dropdown-item-button
        >
      </b-dropdown>

      <template v-else>
        <b-button :key="product.productSku" variant="light" :disabled="disabled || disabledProducts.indexOf(product.productSku) !== -1" class="mr-2 mb-2" size="sm" @click="onAdd(product)">
          <i class="fas fa-plus mr-1" /> {{ product.name }}
        </b-button>
      </template>
    </template>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      }
    },
    onAdd: {
      type: Function,
      default: function () {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledProducts: {
      type: Array,
      default: function () {
        return [];
      }
    }
  }
};
</script>
