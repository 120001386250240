<template>
  <booking-card :edit-mode.sync="editMode" :title="$i18n.tc('coupon.coupon', 2)" :toggle-edit-mode="toggleEditMode" :allow-editing="isStayDiscountPossible(stay.id)">
    <button class="btn btn-sm btn-light mr-2 mb-3" :disabled="newVoucher || !isStayDiscountPossible(stay.id) || (isEditing && !editMode)" @click="addNewVoucher">
      <i class="fa fa-plus mr-1" /> {{ $i18n.tc("coupon.coupon", 1) }}
    </button>

    <div v-if="editMode">
      <b-form-row v-for="discount in $_.orderBy(couponDiscounts, 'id')" :key="discount.id" class="group-item">
        <b-col cols="3" class="form-control-plaintext">{{ discount.campaignItemName }} ({{ discount.description }})</b-col>
        <b-col cols="6" class="form-control-plaintext">Ontvangen {{ discount.createdDate | moment("dddd D MMMM H:mm uur") }}</b-col>
        <b-col cols="3" class="form-control-plaintext text-right">{{ (discount.amountOff * -1) | currency() }}</b-col>
        <div class="item-actions">
          <a class="remove-item" href="#" @click="removeVoucher(discount.id)"><i class="far fa-trash-alt text" /></a>
        </div>
      </b-form-row>

      <b-form-row v-if="newVoucher" class="group-item">
        <b-col cols="3" class="form-control-plaintext">Kortingscode</b-col>
        <b-col cols="4">
          <b-input-group>
            <b-input-group-text slot="prepend"><i class="fas fa-tag"></i></b-input-group-text>
            <b-form-input v-model="newVoucher.voucherCode" placeholder="Kortingscode"></b-form-input>
          </b-input-group>
        </b-col>
        <div class="item-actions">
          <a class="remove-item" href="#" @click="cancelNewVoucher"><i class="far fa-trash-alt text" /></a>
        </div>
      </b-form-row>

      <b-form-row class="mt-3">
        <b-col class="text-right">
          <b-button variant="light" class="mr-2" @click.stop="toggleEditMode">Annuleren</b-button>
          <b-button variant="primary" :disabled="!newVoucher" @click="saveVoucher">Opslaan</b-button>
        </b-col>
      </b-form-row>
    </div>
    <div v-else>
      <b-form-row v-for="discount in $_.orderBy(couponDiscounts, 'id')" :key="discount.id">
        <b-col cols="3" class="form-control-plaintext">{{ discount.campaignItemName }} ({{ discount.description }})</b-col>
        <b-col cols="6" class="form-control-plaintext">Ontvangen {{ discount.createdDate | moment("dddd D MMMM H:mm uur") }}</b-col>
        <b-col cols="3" class="form-control-plaintext text-right">{{ (discount.amountOff * -1) | currency() }}</b-col>
      </b-form-row>
    </div>
  </booking-card>
</template>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BookingCard from "@/components/booking-details/BookingCard";
const StayRepository = RepositoryFactory.get("StayRepository");

import { store, mutations } from "@/store/observable";

export default {
  components: { BookingCard },
  data() {
    return {
      config: config,
      editMode: false,
      newVoucher: null
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      isStayDiscountPossible: "CurrentBooking/isStayDiscountPossible",
      bookingCanBeEdited: "CurrentBooking/canBeEdited"
    }),
    couponDiscounts: function () {
      return this.stay.discounts.filter(function (d) {
        return d.isVoucher;
      });
    },
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }
      if (this.editMode) {
        this.newVoucher = null;
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    addNewVoucher: function () {
      mutations.lockToggle();
      this.editMode = !this.editMode;
      this.newVoucher = { voucherCode: null };
    },
    cancelNewVoucher: function () {
      this.newVoucher = null;
    },
    saveVoucher: function () {
      if (this.newVoucher == null) return;

      StayRepository.addVoucher(this.currentCampsite.id, this.booking.id, this.stay.id, this.newVoucher)
        .then(() => {
          this.fetchBooking({ id: this.booking.id, stayId: this.stay.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },
    removeVoucher: function (discountId) {
      StayRepository.removeVoucher(this.currentCampsite.id, this.booking.id, this.stay.id, discountId)
        .then(() => {
          this.fetchBooking({ id: this.booking.id, stayId: this.stay.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
