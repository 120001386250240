<template>
  <b-modal
    visible
    static
    centered
    title="Aankomstdatum registreren"
    ok-variant="success"
    ok-title="Akkoord"
    cancel-variant="light"
    cancel-title="Annuleren"
    @ok="$close({ result: true, date: selectedDate })"
    @cancel="$close({ result: false })"
    @close="$close({ result: false })"
  >
    <p>Selecteer de aankomstdatum</p>

    <v-date-picker v-model="selectedDate" mode="single" locale="nl" :min-date="minDate" :max-date="maxDate" class="mb-3">
      <b-input-group slot-scope="{ inputValue, updateValue }">
        <b-input-group-text slot="prepend"><i class="far fa-calendar-alt" /></b-input-group-text>
        <b-form-input
          :value="inputValue"
          @input="updateValue($event.target.value, { formatInput: false, hidePopover: false })"
          @change="updateValue($event.target.value, { formatInput: true, hidePopover: false })"
        />
        <b-input-group-text slot="append"><i class="fas fa-eraser" @click.stop="clearSelectedDate" /></b-input-group-text>
      </b-input-group>
    </v-date-picker>

    <b-alert variant="info" :show="isDifferentDate" class="mt-3">
      De geselecteerde datum wijkt af van de ingangsdatum van de boeking. De ingangsdatum van de boeking wordt aangepast aan de aankomstdatum.<br />
    </b-alert>
    <b-alert variant="warning" :show="isEmptyDate" class="mt-3">Er is geen aankomstdatum geselecteerd. Weet je zeker dat je geen aankomstdatum wilt registreren?</b-alert>
  </b-modal>
</template>

<script>
export default {
  props: {
    startDate: { type: Date, default: null },
    endDate: { type: Date, default: null },
    defaultDate: { type: Date, default: new Date() }
  },
  data() {
    return {
      selectedDate: this.defaultDate,
      minDate: this.$moment().subtract({ days: 7 }).toDate(), // limits today - 7 days
      maxDate: null //no limits
    };
  },
  computed: {
    isDifferentDate: function () {
      if (this.startDate == null || this.selectedDate == null) return false;

      return !this.$moment(this.selectedDate).startOf("day").isSame(this.$moment(this.startDate).startOf("day"));
    },
    isEmptyDate: function () {
      return this.startDate != null && this.selectedDate == null;
    }
  },
  methods: {
    updateSelectedDate: function (date) {
      this.selectedDate == date;
    },
    clearSelectedDate: function () {
      this.selectedDate = null;
    }
  }
};
</script>
