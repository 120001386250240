<template>
  <validation-observer v-slot="{ invalid }">
    <b-modal
      visible
      static
      centered
      :title="'Afrekening downloaden van boeking ' + booking.bookingNumber"
      :ok-disabled="invalid"
      ok-variant="success"
      ok-title="Akkoord"
      cancel-variant="light"
      cancel-title="Annuleren"
      @ok="$close({ result: true, stayId })"
      @cancel="$close({ result: false })"
      @close="$close({ result: false })"
    >
      <validation-provider v-slot="v" name="Verblijf" rules="required">
        <b-form-group label="Selecteer het verblijf">
          <b-form-select v-model="stayId" :options="options" :state="getValidationState(v)">
            <template #first>
              <option :value="null" disabled>Selecteer het verblijf</option>
            </template>
          </b-form-select>
        </b-form-group>
      </validation-provider>
    </b-modal>
  </validation-observer>
</template>

<script>
import ValidationMixin from "@/mixins/validation-mixin";

export default {
  mixins: [ValidationMixin],
  props: {
    booking: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      stayId: null
    };
  },
  computed: {
    options: function () {
      return this.booking.stays
        .map((s) => ({
          value: s.id,
          text: `${s.bookingDescription} ${this.$moment(s.startDate).format("D MMMM")} - ${this.$moment(s.endDate).format("D MMMM")}`,
          startDate: s.startDate,
          endDate: s.endDate
        }))
        .sort((a, b) => {
          if (a.startDate > b.startDate) return 1;
          if (a.startDate < b.startDate) return -1;
          if (a.endDate < b.endDate) return 1;
          if (a.endDate > b.endDate) return -1;
        });
    }
  },
  mounted() {
    this.stayId = this.options[0].value;
  }
};
</script>
