import Vue from "vue";

export const store = Vue.observable({
  isEditing: false
});

//TODO: refactor to booking mutation
export const mutations = {
  lockToggle() {
    store.isEditing = !store.isEditing;
  },
  unlock() {
    store.isEditing = false;
  }
};
