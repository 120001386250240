<template>
  <div>
    <b-form-textarea v-model="newComment" type="text" :placeholder="$t('booking.activity.addition_placeholder')" :rows="3"></b-form-textarea>
    <div class="row">
      <div class="offset-xl-6 col-xl-6">
        <div class="input-group">
          <b-button class="form-control mt-2" variant="light" @click="addComment">{{ $t("booking.activity.add") }}</b-button>
        </div>
      </div>
    </div>

    <ul v-if="timelineItems.length" class="list-unstyled">
      <li v-for="(activity, index) in timelineItems.slice(0, limit)" :key="index">
        <h4 v-show="index === 0 || !moment(timelineItems[index - 1].timelineDate).isSame(activity.timelineDate, 'day')">
          {{ activity.timelineDate | moment("D MMMM") }}
        </h4>
        <activity :activity="activity" />
      </li>
    </ul>

    <a v-if="hasShowMoreLink" href="" @click.prevent="$bvModal.show('all-activities-modal')">
      {{ $t("booking.activity.show_more") }}
    </a>

    <b-modal id="all-activities-modal" :title="$tc('booking.activity.activity', 2)" hide-footer>
      <ul v-if="timelineItems.length" class="list-unstyled">
        <li v-for="(activity, index) in timelineItems" :key="index">
          <h4 v-show="index === 0 || !moment(timelineItems[index - 1].timelineDate).isSame(moment(activity.timelineDate), 'day')">
            {{ activity.timelineDate | moment("D MMMM") }}
          </h4>
          <activity :activity="activity" />
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import Activity from "@/components/booking-details/Activity";
const BookingRepository = RepositoryFactory.get("BookingRepository");

export default {
  components: { Activity },

  data() {
    return {
      newComment: "",
      limit: 10
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking" }),
    ...mapState("Campsites", ["currentCampsite"]),
    timelineItems: function () {
      let items = [];
      const moment = this.$moment;

      this.booking.comments.forEach(function (comment) {
        let item = {};
        item.type = "comment";
        item.icon = "far fa-user";
        item.timelineDate = new Date(comment.createdDate);
        item.timestamp = `Om ${moment(item.timelineDate).format("H:mm")} door ${comment.name}`;
        item.message = comment.commentText;
        items.push(item);
      });

      this.booking.activities.forEach(function (activity) {
        let item = {};
        item.type = "activity";
        item.icon = "far fa-file-alt";
        item.timelineDate = new Date(activity.activityDate);
        item.timestamp = `Om ${moment(item.timelineDate).format("H:mm")} door ${activity.name}`;
        item.message = activity.activityText;
        items.push(item);
      });

      return this.$_.orderBy(items, "timelineDate", "desc");
    },
    hasShowMoreLink: function () {
      return this.timelineItems.length > this.limit;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    addComment: function () {
      const comment = { name: "Camping Karel", commentText: this.newComment };

      BookingRepository.createComment(this.currentCampsite.id, this.booking.id, comment)
        .then(() => {
          this.fetchBooking({ id: this.booking.id });
          this.newComment = "";
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
