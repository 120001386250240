<template>
  <span v-if="booking" class="btn btn-sm" :class="paymentStatusClass">
    {{ $t("booking.paymentStates." + booking.paymentStatus) }}
  </span>
</template>

<script>
import config from "@/config";

export default {
  props: {
    booking: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },

  computed: {
    paymentStatusClass: function () {
      if (this.booking.paymentStatus === config.paymentStates.paid) {
        return "btn-outline-green";
      }
      if (this.booking.paymentStatus === config.paymentStates.open) {
        return "btn-outline-yellow";
      }
      if (this.booking.paymentStatus === config.paymentStates.expired) {
        return "btn-outline-red";
      }

      //anything else
      return "btn-outline-gray";
    }
  }
};
</script>
