<template>
  <booking-card :edit-mode.sync="editMode" :title="$i18n.tc('payment.payment', 2)" :toggle-edit-mode="toggleEditMode" :allow-editing="isStayPaymentPossible(stay.id) || isInRole('Supervisor')">
    <button
      v-for="(paymentMethod, index) in paymentMethods"
      :key="index"
      :disabled="newPayment || !isStayPaymentPossible(stay.id) || (isEditing && !editMode)"
      class="btn btn-sm btn-light mr-2 mb-3"
      @click="addNewPayment(paymentMethod.id)"
    >
      <i class="fa fa-plus mr-1" /> {{ paymentMethod.displayName }}
    </button>

    <div v-if="editMode" class="group-item">
      <b-form-row v-for="payment in $_.orderBy(stay.payments, 'id')" :key="payment.id">
        <b-col cols="3" class="form-control-plaintext">{{ payment.paymentMethodName }}</b-col>
        <b-col :cols="isInRole('Supervisor') ? 5 : 6" class="form-control-plaintext">Ontvangen {{ payment.paymentDate | moment("dddd D MMMM H:mm uur") }}</b-col>
        <b-col cols="3" class="form-control-plaintext text-right">{{ payment.amount | currency() }}</b-col>
        <div v-if="isInRole('Supervisor')" class="item-actions">
          <a class="remove-item" href="#" @click="deletePayment(payment)">
            <i class="far fa-trash-alt text"></i>
          </a>
        </div>
      </b-form-row>

      <b-form-row v-if="newPayment" class="group-item">
        <b-col cols="3" class="form-control-plaintext">{{ newPayment.paymentMethod.displayName }}</b-col>
        <b-col cols="4">
          <ValidationProvider v-slot="v" ref="amount" name="Bedrag" rules="double">
            <b-input-group>
              <b-input-group-text slot="prepend"><i class="fas fa-euro-sign"></i></b-input-group-text>
              <b-form-input v-model.number="newPayment.amount" placeholder="Bedrag" :state="getValidationState(v)" />
              <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
            </b-input-group></ValidationProvider
          >
        </b-col>
        <div class="item-actions">
          <a class="remove-item" href="#" @click="cancelNewPayment">
            <i class="far fa-trash-alt text" />
          </a>
        </div>
      </b-form-row>

      <b-form-row class="mt-3">
        <b-col class="text-right">
          <b-button variant="light" class="mr-2" @click.stop="toggleEditMode">Annuleren</b-button>
          <b-button variant="primary" :disabled="!newPayment" @click="savePayment">Opslaan</b-button>
        </b-col>
      </b-form-row>
    </div>
    <div v-else>
      <b-form-row v-for="payment in $_.orderBy(stay.payments, 'id')" :key="payment.id">
        <b-col cols="3" class="form-control-plaintext">{{ payment.paymentMethodName }}</b-col>
        <b-col cols="6" class="form-control-plaintext">Ontvangen {{ payment.paymentDate | moment("dddd D MMMM H:mm uur") }}</b-col>
        <b-col cols="3" class="form-control-plaintext text-right">{{ payment.amount | currency() }}</b-col>
      </b-form-row>
    </div>
  </booking-card>
</template>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BookingCard from "@/components/booking-details/BookingCard";
const StayRepository = RepositoryFactory.get("StayRepository");

import ValidationMixin from "@/mixins/validation-mixin";
import { create } from "vue-modal-dialogs";
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

import { store, mutations } from "@/store/observable";

export default {
  components: { BookingCard },
  mixins: [ValidationMixin],
  data() {
    return {
      config: config,
      editMode: false,
      newPayment: null
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapState("PaymentMethods", ["paymentMethods"]),
    ...mapGetters({
      isStayPaymentPossible: "CurrentBooking/isStayPaymentPossible",
      getPaymentMethodById: "PaymentMethods/getById",
      isInRole: "Users/isInRole"
      //bookingCanBeEdited: "CurrentBooking/canBeEdited"
    }),
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }
      if (this.editMode) {
        this.newPayment = null;
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },
    addNewPayment: function (paymentMethodId) {
      mutations.lockToggle();
      this.editMode = !this.editMode;

      let paymentMethod = this.getPaymentMethodById(paymentMethodId);

      this.newPayment = {
        paymentMethod: paymentMethod,
        paymentMethodId: paymentMethodId,
        amount: this.stay.remainingTotal
      };
    },
    cancelNewPayment: function () {
      this.newPayment = null;
    },
    confirmPayment: function () {
      this.$refs[`paymentMethod${this.newPayment.paymentMethodId}Confirmation`].show();
    },
    deletePayment: async function (payment) {
      confirmationDialog(
        "Betaling verwijderen",
        "Weet u zeker dat u deze betaling wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
        `${payment.paymentMethodName} ${this.$moment(payment.paymentDate).format("dddd D MMMM H:mm uur")} ${this.$options.filters.currency(payment.amount)}`,
        "danger"
      ).then((r) => {
        if (r) {
          StayRepository.deletePayment(this.currentCampsite.id, this.booking.id, this.stay.id, payment.id)
            .then(() => {
              this.fetchBooking({
                id: this.booking.id,
                stayId: this.stay.id
              }).then(() => {
                this.toggleEditMode();
              });
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    savePayment: async function () {
      this.$refs.amount.validate().then((success) => {
        if (this.newPayment == null || !success.valid) return;

        confirmationDialog("Betaling bevestigen", `Bevestig de betaling voor ${this.newPayment.paymentMethod.displayName}`, this.newPayment.paymentMethod.description, "info").then((r) => {
          if (r) {
            let payment = this.newPayment;
            payment.amount = this.newPayment.amount;
            StayRepository.createPayment(this.currentCampsite.id, this.booking.id, this.stay.id, payment)
              .then(() => {
                this.fetchBooking({
                  id: this.booking.id,
                  stayId: this.stay.id
                }).then(() => {
                  this.toggleEditMode();
                });
              })
              .catch((error) => {
                this.$notify({
                  group: "app",
                  type: "error",
                  title: this.$i18n.t("general_error_title"),
                  text: error.response.data.message
                });
              });
          }
        });
      });
    }
  }
};
</script>
