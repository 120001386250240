<template>
  <b-modal
    id="create-stay"
    ref="createStay"
    v-model="modalShow"
    size="lg"
    title="Verblijf toevoegen"
    ok-title="Verblijf toevoegen"
    cancel-title="Annuleren"
    ok-variant="success"
    cancel-variant="light"
    @ok.prevent="createBooking"
  >
    <b-form-row>
      <b-form-group label="Periode" :label-cols="3" class="col-sm-12">
        <v-date-picker
          mode="range"
          is-required
          locale="nl"
          :value="{ start: stay.startDate, end: stay.endDate }"
          :min-date="moment(booking.startDate).toDate()"
          :max-date="moment(booking.endDate).toDate()"
          :columns="2"
          @input="onDateRangeChanged($event)"
        >
          <b-input-group slot-scope="{ inputValue, updateValue }" class="date-picker">
            <b-input-group-text slot="prepend"><i class="far fa-calendar-alt" /></b-input-group-text>
            <b-form-input :class="[{ 'show-day-span': true }]" :value="inputValue" @change.native="updateValue($event.target.value)" />
            <b-input-group-text slot="append" class="date-range"> {{ accommodationDaysSpan }} nachten </b-input-group-text>
          </b-input-group>
        </v-date-picker>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group :label="$t('guest.guests')" :label-cols="3" class="col-sm-12">
        <b-form-row>
          <b-col v-for="product in guestsBySku(config.products.skuPrefixes.guests.adult)" :key="product.productSku" cols="4" class="form-group">
            <label>{{ product.name }}</label>
            <input-spinner integer :value="numberOfGuests(product.productSku)" :min="0" :max="9" @input="onGuestCountChanged(product.productSku, $event)" />
          </b-col>
          <b-col v-for="product in guestsBySku(config.products.skuPrefixes.guests.kids)" :key="product.productSku" cols="4" class="form-group">
            <label>{{ product.name }}</label>
            <input-spinner integer :value="numberOfGuests(product.productSku)" :min="0" :max="9" @input="onGuestCountChanged(product.productSku, $event)" />
          </b-col>
        </b-form-row>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group label="Logees" :label-cols="3" class="col-sm-12">
        <b-form-row>
          <b-col v-for="product in guestsBySku(config.products.skuPrefixes.visitors.all)" :key="product.productSku" cols="4" class="form-group">
            <label>{{ product.name }}</label>
            <input-spinner integer :value="numberOfGuests(product.productSku)" :min="0" :max="9" @input="onGuestCountChanged(product.productSku, $event)" />
          </b-col>
        </b-form-row>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group label="Toeristenbelasting" :label-cols="3" class="col-sm-12">
        <b-form-select
          :options="touristTaxes"
          :value="stay.touristTaxSkus[0] || null"
          text-field="name"
          value-field="productSku"
          class="mb-3"
          name="touristTax"
          @change="onItemUpdated('touristTaxSkus', $event)"
        >
          <template #first>
            <option :value="null">{{ $t("tourist_tax.empty") }}</option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="false">
      <b-form-group :label="$t('electricity.electricity')" :label-cols="3" class="col-sm-12">
        <ValidationProvider v-slot="v" :name="$t('electricity.electricity')" rules="required">
          <b-form-select
            :options="electricities"
            :value="stay.electricitySkus[0] || null"
            text-field="name"
            value-field="productSku"
            class="mb-3"
            name="electricity"
            :state="getValidationState(v)"
            @change="onItemUpdated('electricitySkus', $event)"
          >
            <template #first>
              <option :value="null" disabled>{{ $t("electricity.empty") }}</option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>
      </b-form-group>
    </b-form-row>
  </b-modal>
</template>

<style lang="scss"></style>

<script>
import config from "@/config";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { DateHelpers } from "@/helpers.js";

import ValidationMixin from "@/mixins/validation-mixin";
import InputSpinner from "@/components/common/InputSpinner.vue";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const StayRepository = RepositoryFactory.get("StayRepository");
const ProductRepository = RepositoryFactory.get("ProductRepository");

export default {
  components: { InputSpinner },
  mixins: [ValidationMixin],

  data() {
    return {
      config: config,
      modalShow: false,

      products: [],
      touristTaxes: [],
      electricities: [],

      stay: {}
    };
  },

  computed: {
    ...mapState("CurrentBooking", ["booking"]),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      getStay: "CurrentBooking/getStay",
      newStayDateRange: "CurrentBooking/newStayDateRange"
    }),
    accommodationDaysSpan: function () {
      if (this.stay.startDate && this.stay.endDate) {
        return DateHelpers.getDaysSpan({ start: this.stay.startDate, end: this.stay.endDate });
      }
      return null;
    }
  },

  watch: {
    modalShow: function (show) {
      if (show) {
        this.reset();
        this.fetchProducts();
      }
    }
  },

  created() {
    this.reset();
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    ...mapMutations("CurrentBooking", ["setSelectedStay"]),

    numberOfGuests(sku) {
      return this.stay.guestSkus.filter((guestSku) => guestSku === sku).length;
    },
    guestsBySku: function (sku) {
      return this.products.filter((p) => p.productDefinition == "Guest" && p.productSku.startsWith(sku));
    },

    reset() {
      this.stay = {
        startDate: this.newStayDateRange.start.toDate(),
        endDate: this.newStayDateRange.end.toDate(),
        guestSkus: [],
        electricitySkus: [],
        touristTaxSkus: [],
        otherProductSkus: []
      };
    },

    onOkCreateClicked: function () {
      this.createBooking();
    },
    onDateRangeChanged(range) {
      if (!range) return;

      this.stay.startDate = range.start;
      this.stay.endDate = range.end;
    },
    onItemUpdated(bookingProperty, value) {
      if (value !== null) {
        this.stay[bookingProperty] = [value];
      } else {
        this.stay[bookingProperty] = [];
      }
    },
    onGuestCountChanged(sku, count) {
      while (this.numberOfGuests(sku) > count) {
        let guestIndex = this.stay.guestSkus.findIndex((guestSku) => guestSku === sku);
        this.stay.guestSkus.splice(guestIndex, 1);
      }

      while (this.numberOfGuests(sku) < count) {
        this.stay.guestSkus.push(sku);
      }
    },

    fetchProducts() {
      let bookingTypeQueryType = config.bookingTypes.stay;

      ProductRepository.getProducts(this.currentCampsite.id, bookingTypeQueryType)
        .then((response) => {
          let products = [];
          response.data.forEach((p) => {
            if (p.variants.length === 0) {
              products.push(p);
            } else {
              products = products.concat(p.variants);
            }
          });

          this.products = products;
          this.touristTaxes = this.products.filter((p) => p.productDefinition == "TouristTax");
          this.electricities = this.products.filter((p) => p.productDefinition == "Electricity");
          this.otherProducts = this.products.filter((p) => p.productDefinition == "Product");

          this.setBookingDefaults();
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },
    createBooking() {
      StayRepository.create(this.currentCampsite.id, this.booking.id, this.stay)
        .then((response) => {
          if (response) {
            this.$refs.createStay.hide();
            this.fetchBooking({ id: this.booking.id, stayId: response });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },
    setBookingDefaults() {
      this.onDateRangeChanged({
        start: this.newStayDateRange.start.toDate(),
        end: this.newStayDateRange.end.toDate()
      });

      this.onGuestCountChanged(this.config.products.skuPrefixes.guests.adult, 2);

      if (this.touristTaxes.length) {
        let item = this.touristTaxes[0];
        this.onItemUpdated("touristTaxSkus", item.productSku);
      }

      if (this.electricities.length) {
        //NOTE: the line below is temporarily commented out to avoid that ELC is automatically added to a member's booking
        //      while we do not have a solution in place to visually indicate that the new startPosition has been updated in the meantime
        this.onItemUpdated("electricitySkus", null);
        //let item = DateHelpers.getFirstInRangeForDate(this.electricities, Date.now()) || this.electricities[0];
        //this.onItemUpdated("electricitySkus", item.productSku);
      }
    }
  }
};
</script>
