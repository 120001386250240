<template>
  <div class="payment-details">
    <stay-vouchers />

    <stay-payments />
  </div>
</template>

<style lang="scss" scoped>
.payment-details {
  .card:first-child {
    border-top-style: solid;
    border-bottom-style: dashed;
  }
  .card:not(:first-child):not(:last-child) {
    border-top-width: 0;
    border-bottom-style: dashed;
  }
  .card:last-child {
    border-top-width: 0;
    border-bottom-style: solid;
  }
}
</style>

<script>
//import { mapState, mapGetters } from "vuex";
import StayPayments from "@/components/booking-details/StayPayments";
import StayVouchers from "@/components/booking-details/StayVouchers";
//import config from "@/config";

export default {
  components: {
    StayVouchers,
    StayPayments
  }

  // props: {
  //   id: {
  //     type: Number,
  //     default: null
  //   }
  // }

  // data: function() {
  //   return {
  //     config: config,
  //     products: []
  //   };
  // },

  // computed: {
  //   ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
  //   ...mapState("Campsites", ["currentCampsite"]),
  //   ...mapGetters({
  //     getBookingQueryType: "CurrentBooking/getBookingQueryType"
  //   })
  // }
};
</script>
