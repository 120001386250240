<template>
  <booking-card title="Verblijf" :edit-mode.sync="editMode" :toggle-edit-mode="toggleEditMode" :allow-editing="isEditingAllowed">
    <div v-if="editMode">
      <div class="row group-item">
        <div class="col-3">Verblijf</div>
        <div class="col-9">
          <v-date-picker mode="range" is-required locale="nl" :value="{ start: stay.startDate, end: stay.endDate }" :min-date="minDate" :max-date="maxDate" :columns="2" @input="dateRange = $event">
            <b-input-group slot-scope="{ inputValue, updateValue }" class="date-picker">
              <b-input-group-text slot="prepend"><i class="far fa-calendar-alt" /></b-input-group-text>
              <b-form-input :class="[{ 'show-day-span': true }]" :value="inputValue" @change.native="updateValue($event.target.value)" />
              <b-input-group-text slot="append" class="date-range">
                {{ getDaysSpan(dateRange || { start: stay.startDate, end: stay.endDate }) || null }}
              </b-input-group-text>
            </b-input-group>
          </v-date-picker>
        </div>
      </div>

      <div class="row group-actions">
        <div class="col text-right">
          <button class="btn btn-light mr-2" @click.stop="toggleEditMode">Annuleren</button>
          <button class="btn btn-primary" @click="save">Opslaan</button>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-3">Verblijf</div>
      <div class="col-2">{{ stay.nightCount }} nachten</div>
      <div class="col-7">{{ stay.startDate | moment("ll") }} - {{ stay.endDate | moment("ll") }}</div>
    </div>
  </booking-card>
</template>

<style lang="scss" scoped>
.booking-details .card:not(:first-child):not(:last-child) {
  border-bottom: 0;
}
</style>

<script>
import config from "@/config";
import { mapState, mapGetters, mapActions } from "vuex";
import { DateHelpers } from "@/helpers.js";
import BookingCard from "@/components/booking-details/BookingCard";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const StayRepository = RepositoryFactory.get("StayRepository");

import { create } from "vue-modal-dialogs";
import NotificationDialog from "@/components/common/Dialogs/NotificationDialog";
const notificationDialog = create(NotificationDialog, "title", "message", "alert", "alertVariant");
import ConfirmationDialog from "@/components/common/Dialogs/ConfirmationDialog";
const confirmationDialog = create(ConfirmationDialog, "title", "message", "alert", "alertVariant");

import { store, mutations } from "@/store/observable";

export default {
  components: { BookingCard },

  props: {},

  data() {
    return {
      config: config,
      editMode: false,
      dateRange: null
    };
  },

  computed: {
    ...mapState("CurrentBooking", { booking: "booking", stay: "selectedStay" }),
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      hasPayments: "CurrentBooking/hasPayments",
      isEditingAllowed: "CurrentBooking/isEditingAllowed",
      isCheckedIn: "CurrentBooking/isCheckedIn",
      isCheckedOut: "CurrentBooking/isCheckedOut"
    }),
    minDate: function () {
      return this.isCheckedIn ? this.booking.startDate : null;
    },
    maxDate: function () {
      return this.isCheckedOut ? this.booking.endDate : null;
    },
    isEditing() {
      return store.isEditing;
    }
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),

    toggleEditMode: async function () {
      if (this.isEditing && !this.editMode) {
        return;
      }

      if (!this.editMode) {
        if (this.isCheckedOut && (await notificationDialog("Vertrek geregistreerd", "Voor deze boeking is een vertrek geregistreerd. De duur van het verblijf kan niet meer worden aangepast."))) {
          return;
        }

        if (
          this.hasPayments(this.stay.id) &&
          (await confirmationDialog(
            "Verblijf wijzigen",
            "Voor deze boeking zijn één of meerdere betalingen geregistreerd. Weet je zeker dat je de duur van het verblijf wilt wijzigen?",
            "Het aanpassen hiervan kan gevolgen hebben voor het totaalbedrag van deze boeking.",
            "warning"
          )) == false
        ) {
          return;
        }

        //this.editedAccommodations = this.$_.cloneDeep(this.stay.accommodations);
      }

      mutations.lockToggle();
      this.editMode = !this.editMode;
    },

    getDaysSpan(dates) {
      var count = DateHelpers.getDaysSpan(dates);
      return this.$i18n.tc("booking.nights", count);
    },
    onBookingDateRangeUpdated: async function () {
      // let currentAvailablePitches = 0;
      // let previousAvailablePitches = 0;
      // let pitchType = this.bookingType == config.bookingTypes.longStorage ? config.pitchTypes.StoragePitch : config.pitchTypes.CampingPitch;
      // await MetricRepository.getAvailability(this.currentCampsite.id, this.booking.startDate, this.booking.endDate, pitchType).then((response) => {
      //   let availability = response.data.dimensions.find((x) => x.name === "Available").value;
      //   previousAvailablePitches = parseInt(availability, 10);
      // });
      // await MetricRepository.getAvailability(
      //   this.currentCampsite.id,
      //   this.$moment(range.start).startOf("day").add({ hours: 12 }).toDate(),
      //   this.$moment(range.end).startOf("day").add({ hours: 12 }).toDate(),
      //   pitchType
      // ).then((response) => {
      //   let availability = response.data.dimensions.find((x) => x.name === "Available").value;
      //   currentAvailablePitches = parseInt(availability, 10);
      // });
      // if (
      //   currentAvailablePitches > config.availabilityTreshold.warning ||
      //   previousAvailablePitches <= currentAvailablePitches ||
      //   (currentAvailablePitches <= this.config.availabilityTreshold.danger &&
      //     (await confirmationDialog(
      //       this.$i18n.t("booking.no_availability_title"),
      //       this.$i18n.t("booking.no_availability"),
      //       this.$i18n.t("booking.availability", {
      //         count: currentAvailablePitches,
      //         startDate: this.$moment(range.start).format("D MMMM"),
      //         endDate: this.$moment(range.end).format("D MMMM")
      //       }),
      //       "danger"
      //     ))) ||
      //   previousAvailablePitches < currentAvailablePitches ||
      //   (currentAvailablePitches >= this.config.availabilityTreshold.danger &&
      //     currentAvailablePitches <= this.config.availabilityTreshold.warning &&
      //     (await confirmationDialog(
      //       this.$i18n.t("booking.low_availability_title"),
      //       this.$i18n.t("booking.low_availability"),
      //       this.$i18n.t("booking.availability", {
      //         count: currentAvailablePitches,
      //         startDate: this.$moment(range.start).format("D MMMM"),
      //         endDate: this.$moment(range.end).format("D MMMM")
      //       }),
      //       "warning"
      //     )))
      // ) {
      //   if (null === this.booking.checkInDate) {
      //     this.editedAccommodations[index].startDate = range.start;
      //   } else if (!this.$moment(this.booking.checkInDate).startOf("day").isSame(this.$moment(range.start).startOf("day"))) {
      //     this.$notify({
      //       group: "app",
      //       type: "error",
      //       title: this.$i18n.t("general_error_title"),
      //       text: this.$i18n.t("accommodation.start_date_change_after_arrival_error")
      //     });
      //   }
      //   this.editedAccommodations[index].endDate = range.end;
      // }
      //this.dateRange = range;
      // console.log(range);
      // console.log(this.dateRange);
    },
    save: function () {
      StayRepository.updateDateRange(this.currentCampsite.id, this.booking.id, this.stay.id, this.dateRange.start, this.dateRange.end)
        .then(() => {
          this.fetchBooking({ id: this.booking.id }).then(() => {
            this.toggleEditMode();
          });
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
